import React, { useState } from 'react';
import { Upload, Image, AlertCircle } from 'lucide-react';
import { Alert, AlertDescription } from '../ui/alert';



const CompanyLogoUpload = ({ currentLogo, onLogoChange, error }) => {
  const [previewUrl, setPreviewUrl] = useState(currentLogo || null);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      validateAndProcessFile(file);
    }
  };

  const validateAndProcessFile = (file) => {
    // Check file size (5MB limit)
    if (file.size > 5 * 1024 * 1024) {
      onLogoChange(null, 'Logo file size must be less than 5MB');
      return;
    }

    // Check file type
    if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      onLogoChange(null, 'Logo must be a JPEG, PNG, or GIF image');
      return;
    }

    // Create preview URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);

    // Pass file to parent
    onLogoChange(file, null);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    
    const file = e.dataTransfer.files[0];
    if (file) {
      validateAndProcessFile(file);
    }
  };

  return (
    <div className="space-y-4">
      <div 
        className={`relative border-2 border-dashed rounded-lg p-4 text-center cursor-pointer
          ${isDragging ? 'border-orange-500 bg-orange-50' : 'border-gray-300 hover:border-orange-500'}
          transition-colors duration-200`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          accept="image/jpeg,image/png,image/gif"
          className="hidden"
          onChange={handleFileChange}
          id="logo-upload"
        />
        <label 
          htmlFor="logo-upload"
          className="block cursor-pointer"
        >
          {previewUrl ? (
            <div className="flex flex-col items-center gap-4">
              <img 
                src={previewUrl}
                alt="Company logo preview"
                className="max-h-32 w-auto object-contain rounded"
              />
              <div className="flex items-center gap-2 text-sm text-gray-600">
                <Upload className="w-4 h-4" />
                Click or drag to replace logo
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-2 py-8">
              <Upload className="w-8 h-8 text-gray-400" />
              <div className="text-sm text-gray-600">
                Click or drag image to upload company logo
              </div>
              <div className="text-xs text-gray-500">
                JPEG, PNG or GIF (max. 5MB)
              </div>
            </div>
          )}
        </label>
      </div>

      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default CompanyLogoUpload;