// apiClient.js
import axios from "axios";

const isTokenExpired = () => {
  const token = localStorage.getItem("accessToken");

  if (!token) return true;

  try {
    const tokenPayload = JSON.parse(atob(token.split(".")[1]));
    const expiryTime = tokenPayload.exp * 1000;
    const currentTime = Date.now();

    return currentTime >= expiryTime;
  } catch (error) {
    console.error("Error parsing token:", error);
    return true;
  }
};

const createApiClient = (onSessionExpired) => {
  const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/`,
  });

  apiClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        if (isTokenExpired()) {
          localStorage.removeItem("accessToken");
          if (onSessionExpired) {
            onSessionExpired();
          }
        } else {
          if (onSessionExpired) {
            onSessionExpired();
          }
        }
      }
      return Promise.reject(error);
    }
  );

  return apiClient;
};

export default createApiClient;