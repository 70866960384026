import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Lock, ArrowRight, AlertCircle, Check, X } from 'lucide-react';
import AuthFormWrapper from './authFormWrapper';
import StyledButton from '../ui/styledButton';
import StyledInput from '../ui/styledInput';

const PasswordResetConfirmScreen = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
    matching: false
  });
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const navigate = useNavigate();
  
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uidb64 = searchParams.get('uid');
  const token = searchParams.get('token');

  useEffect(() => {
    if (!token || !uidb64) {
      setApiError('Invalid or expired password reset link');
    }
  }, [token, uidb64]);

  // Real-time password validation
  useEffect(() => {
    setValidations({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      matching: password === confirmPassword && password.length > 0
    });
  }, [password, confirmPassword]);

  const ValidationItem = ({ isValid, text }) => (
    <li className="flex items-center space-x-2">
      {isValid ? (
        <Check className="h-4 w-4 text-green-500" />
      ) : (
        <X className="h-4 w-4 text-red-500" />
      )}
      <span className={`text-sm ${isValid ? 'text-green-600' : 'text-red-600'}`}>
        {text}
      </span>
    </li>
  );

  const handleResetConfirm = async (e) => {
    e.preventDefault();
    setApiError('');
    
    const isValid = Object.values(validations).every(v => v);
    if (!isValid) return;

    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password/${uidb64}/${token}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          new_password: password,
          new_password2: confirmPassword,
          token: token,
          uidb64: uidb64,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        if (data.token) {
          throw new Error('This password reset link has expired or is invalid');
        }
        if (data.new_password) {
          throw new Error(Array.isArray(data.new_password) 
            ? data.new_password[0] 
            : data.new_password);
        }
        throw new Error(data.error || 'Failed to reset password');
      }

      navigate('/login', { 
        state: { message: 'Password has been reset successfully. Please login with your new password.' }
      });

    } catch (error) {
      setApiError(error.message || 'Failed to reset password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (apiError === 'Invalid or expired password reset link') {
    return (
      <AuthFormWrapper title="Invalid Reset Link">
        <div className="mt-8 space-y-6">
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative flex items-center" role="alert">
            <AlertCircle className="h-4 w-4 mr-2 flex-shrink-0" />
            <span className="block sm:inline">This password reset link has expired or is invalid.</span>
          </div>
          <StyledButton 
            onClick={() => navigate('/password-reset')}
            className="w-full"
          >
            Request New Reset Link
          </StyledButton>
        </div>
      </AuthFormWrapper>
    );
  }

  // Calculate if the form is valid
  const isFormValid = Object.values(validations).every(v => v);

  return (
    <AuthFormWrapper title="Set new password">
      <div className="mt-8 space-y-6">
        {apiError && (
          <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative flex items-center" role="alert">
            <AlertCircle className="h-4 w-4 mr-2 flex-shrink-0" />
            <span className="block sm:inline">{apiError}</span>
          </div>
        )}
        
        <form onSubmit={handleResetConfirm} className="space-y-4">
          <div className="space-y-4">
            <StyledInput
              icon={Lock}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="New password"
              required
              aria-label="New password"
            />
            <StyledInput
              icon={Lock}
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm new password"
              required
              aria-label="Confirm new password"
            />
          </div>

          {/* Enhanced password requirements with visual feedback */}
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <h3 className="text-sm font-medium text-gray-700 mb-2">Password Requirements:</h3>
            <ul className="space-y-2">
              <ValidationItem 
                isValid={validations.length} 
                text="At least 8 characters long"
              />
              <ValidationItem 
                isValid={validations.uppercase} 
                text="Contains an uppercase letter"
              />
              <ValidationItem 
                isValid={validations.lowercase} 
                text="Contains a lowercase letter"
              />
              <ValidationItem 
                isValid={validations.number} 
                text="Contains a number"
              />
              <ValidationItem 
                isValid={validations.special} 
                text="Contains a special character (!@#$%^&*(),.?\:{}|<>)"
              />
              <ValidationItem 
                isValid={validations.matching} 
                text="Passwords match"
              />
            </ul>
          </div>

          <div>
            <StyledButton 
              type="submit" 
              isLoading={isLoading}
              disabled={isLoading || !isFormValid}
            >
              {isLoading ? 'Setting new password...' : 'Set new password'}
              <ArrowRight className="ml-2 h-4 w-4" />
            </StyledButton>
          </div>
        </form>
      </div>
    </AuthFormWrapper>
  );
};

export default PasswordResetConfirmScreen;