import { Toast } from '@chakra-ui/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}/api/`,
  prepareHeaders: (headers) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    localStorage.removeItem('accessToken');
    Toast({
      title: "Session Expired",
      description: "Your session has expired. Please login again.",
      status: "warning",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
    setTimeout(() => {  
      window.location.href = "/login";
    }, 1500);
    return result;
  }

  return result;
};

const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Project', 'ChatHistory', 'ScopeData', 'Document'],
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    // Existing endpoints
    getProjects: builder.query({
      query: () => 'projects/',
      providesTags: ['Project']
    }),
    getProjectById: builder.query({
      query: (id) => `projects/${id}/`,
      providesTags: (result, error, id) => [{ type: 'Project', id }]
    }),
    createProject: builder.mutation({
      query: (newProject) => ({
        url: 'create-project/',
        method: 'POST',
        body: newProject,
      }),
      invalidatesTags: ['Project']
    }),
    getChatHistory: builder.query({
      query: (projectId) => `chat-history/?id=${projectId}`,
      providesTags: (result, error, projectId) => [{ type: 'ChatHistory', id: projectId }]
    }),

    // Scope data endpoints
    getScopeData: builder.query({
      query: (projectId) => `scope-data/${projectId}/`,
      providesTags: (result, error, projectId) => [
        { type: 'ScopeData', id: projectId },
        { type: 'Project', id: projectId }
      ]
    }),
    updateScopeData: builder.mutation({
      query: ({ projectId, data }) => ({
        url: `scope-data/${projectId}/`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error, { projectId }) => [
        { type: 'ScopeData', id: projectId },
        { type: 'Project', id: projectId }
      ]
    }),

    // Updated Document endpoints to match Django URLs
    getDocument: builder.query({
      query: (projectId) => `scope-data/${projectId}/document/`,
      providesTags: (result, error, projectId) => [{ type: 'Document', id: projectId }]
    }),
    
    storePdf: builder.mutation({
      query: ({ projectId, pdf_content }) => ({
        url: `scope-data/${projectId}/store_pdf/`,
        method: 'POST',
        body: { pdf_content },
      }),
      invalidatesTags: (result, error, { projectId }) => [{ type: 'Document', id: projectId }]
    }),

    uploadVideo: builder.mutation({
      query: ({ video, transcript }) => {
        const formData = new FormData();
        formData.append('video', video);
        formData.append('transcript', transcript);
        
        return {
          url: 'upload-video/',
          method: 'POST',
          body: formData,
          // Don't set Content-Type header as it's automatically set for FormData
          formData: true,
        };
      },
      // Optionally invalidate related tags if needed
      invalidatesTags: ['Project']
    }),

    shareDocument: builder.mutation({
      query: ({ documentId, email, message }) => ({
        url: `documents/${documentId}/share/`,
        method: 'POST',
        body: { email, message },
      }),
      // No need to invalidate tags as this doesn't change any data
      // But we can add error handling
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          Toast({
            title: "Success",
            description: "Document shared successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        } catch (error) {
          Toast({
            title: "Error",
            description: error.error?.data?.error || "Failed to share document",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
    }),

  }),
});

export const {
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useCreateProjectMutation,
  useGetChatHistoryQuery,
  useGetScopeDataQuery,
  useGetDocumentQuery,
  useStorePdfMutation,
  useUploadVideoMutation,
  useShareDocumentMutation
} = apiSlice;

export default apiSlice;