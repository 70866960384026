import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  InputRightElement,
  IconButton,
  useToast,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const uid = searchParams.get("uid");
  const token = searchParams.get("token");
  const navigate = useNavigate();
  
  // State management
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const toast = useToast();

  // Check for missing parameters
  useEffect(() => {
    if (!uid || !token) {
      toast({
        title: "Invalid Reset Link",
        description: "The password reset link appears to be invalid or expired.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      navigate("/login");
    }
  }, [uid, token, navigate, toast]);

  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long");
    }
    if (!/[A-Z]/.test(password)) {
      errors.push("Password must contain at least one uppercase letter");
    }
    if (!/[a-z]/.test(password)) {
      errors.push("Password must contain at least one lowercase letter");
    }
    if (!/[0-9]/.test(password)) {
      errors.push("Password must contain at least one number");
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push("Password must contain at least one special character");
    }
    return errors;
  };

  const validateForm = () => {
    const formErrors = {};
    const passwordErrors = validatePassword(password);
    
    if (passwordErrors.length > 0) {
      formErrors.password = passwordErrors;
    }
    
    if (!confirmPassword) {
      formErrors.confirmPassword = ["Confirm Password is required"];
    } else if (password !== confirmPassword) {
      formErrors.confirmPassword = ["Passwords do not match"];
    }
    
    return formErrors;
  };

  const handleResetPassword = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const payload = {
      new_password: password,
      new_password2: confirmPassword,
      uidb64: uid,
      token: token,
    };

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/reset-password/${uid}/${token}/`,
        payload
      );

      toast({
        title: "Password Reset Successful",
        description: "You can now log in with your new password.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      
      // Clear form fields and redirect
      setPassword("");
      setConfirmPassword("");
      navigate("/login");
      
    } catch (error) {
      const errorMessage = error.response?.data?.error || 
                          error.response?.data?.message ||
                          "Failed to reset password. Please try again.";
                          
      setErrors({
        ...errors,
        backend: Array.isArray(errorMessage) ? errorMessage : [errorMessage]
      });
      
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderPasswordRequirements = () => (
    <div className="text-sm text-gray-600 mt-2">
      Password must:
      <ul className="list-disc pl-5">
        <li>Be at least 8 characters long</li>
        <li>Contain at least one uppercase letter</li>
        <li>Contain at least one lowercase letter</li>
        <li>Contain at least one number</li>
        <li>Contain at least one special character</li>
      </ul>
    </div>
  );

  return (
    <div className="flex items-center justify-center mx-0 min-h-screen bg-gray-100">
      <div className="w-full max-w-lg p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">
          Reset Password
        </h2>

        {errors.backend && (
          <Alert status="error" mb={4}>
            <AlertIcon />
            {errors.backend}
          </Alert>
        )}

        <FormControl isInvalid={errors.password} mb={4}>
          <FormLabel>New Password</FormLabel>
          <InputGroup>
            <Input
              type={isPasswordVisible ? "text" : "password"}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrors({ ...errors, password: null });
              }}
              placeholder="Enter new password"
              size="lg"
              focusBorderColor="blue.500"
            />
            <InputRightElement>
              <IconButton
                variant="ghost"
                icon={isPasswordVisible ? <ViewOffIcon /> : <ViewIcon />}
                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                aria-label={isPasswordVisible ? "Hide password" : "Show password"}
              />
            </InputRightElement>
          </InputGroup>
          {errors.password && (
            <FormErrorMessage>
              {Array.isArray(errors.password) 
                ? errors.password.map((err, idx) => (
                    <div key={idx}>{err}</div>
                  ))
                : errors.password}
            </FormErrorMessage>
          )}
          {renderPasswordRequirements()}
        </FormControl>

        <FormControl isInvalid={errors.confirmPassword} mb={6}>
          <FormLabel>Confirm Password</FormLabel>
          <InputGroup>
            <Input
              type={isConfirmPasswordVisible ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setErrors({ ...errors, confirmPassword: null });
              }}
              placeholder="Confirm your new password"
              size="lg"
              focusBorderColor="blue.500"
            />
            <InputRightElement>
              <IconButton
                variant="ghost"
                icon={isConfirmPasswordVisible ? <ViewOffIcon /> : <ViewIcon />}
                onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
                aria-label={isConfirmPasswordVisible ? "Hide confirm password" : "Show confirm password"}
              />
            </InputRightElement>
          </InputGroup>
          {errors.confirmPassword && (
            <FormErrorMessage>
              {Array.isArray(errors.confirmPassword)
                ? errors.confirmPassword.map((err, idx) => (
                    <div key={idx}>{err}</div>
                  ))
                : errors.confirmPassword}
            </FormErrorMessage>
          )}
        </FormControl>

        <Button
          isLoading={isLoading}
          loadingText="Resetting Password..."
          colorScheme="orange"
          size="lg"
          width="full"
          onClick={handleResetPassword}
        >
          Reset Password
        </Button>
      </div>
    </div>
  );
};

export default ResetPassword;