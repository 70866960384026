import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { User, Lock, Mail, ArrowRight, Shield } from 'lucide-react';
import axios from 'axios';
import AuthFormWrapper from './authFormWrapper';
import StyledButton from '../ui/styledButton';
import StyledInput from '../ui/styledInput';

// Create axios instance with default config
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:8000/',
  headers: {
    'Content-Type': 'application/json'
  }
});

const parseErrorString = (errorStr) => {
  try {
    // Handle stringified error objects
    if (typeof errorStr === 'string' && errorStr.includes('ErrorDetail')) {
      // Extract the actual message from the error string
      const matches = errorStr.match(/string='([^']+)'/);
      if (matches && matches[1]) {
        return matches[1];
      }
    }
    return errorStr;
  } catch (e) {
    return errorStr;
  }
};

const RegisterScreen = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showOtpField, setShowOtpField] = useState(false);
  const navigate = useNavigate();

  const handleError = (error) => {
    if (error.response?.data) {
      const newErrors = {};
      const errorData = error.response.data;

      // Handle error object or string
      if (errorData.error) {
        const parsedError = parseErrorString(errorData.error);
        if (typeof parsedError === 'string') {
          newErrors.general = parsedError;
        } else {
          // Handle nested error objects
          Object.keys(parsedError).forEach(key => {
            newErrors[key] = parseErrorString(parsedError[key]);
          });
        }
      } else {
        // Handle direct error fields
        const errorFields = [
          'username', 'email', 'password', 'password2', 
          'otp', 'general', 'non_field_errors', 'detail'
        ];
        
        errorFields.forEach(field => {
          if (errorData[field]) {
            newErrors[field] = Array.isArray(errorData[field])
              ? errorData[field][0]
              : parseErrorString(errorData[field]);
          }
        });
      }

      // If no specific errors found, use a general error message
      if (Object.keys(newErrors).length === 0) {
        newErrors.general = 'An error occurred. Please try again.';
      }

      setErrors(newErrors);
    } else if (error.message) {
      setErrors({
        general: error.message === 'Network Error'
          ? 'Unable to connect to the server. Please check your internet connection.'
          : parseErrorString(error.message)
      });
    } else {
      setErrors({ general: 'An unexpected error occurred. Please try again.' });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!username) newErrors.username = 'Username is required';
    if (!email) newErrors.email = 'Email is required';
    if (!password) newErrors.password = 'Password is required';
    if (password !== confirmPassword) newErrors.password2 = 'Passwords do not match';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      setErrors({});
      setSuccessMessage('');
      
      try {
        const response = await api.post('/auth/register/', {
          username,
          email,
          password,
          password2: confirmPassword
        });

        if (response.status === 201) {
          setShowOtpField(true);
          setSuccessMessage(response.data.message || 'Please check your email for verification OTP.');
          setErrors({});
        }
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!otp) {
      setErrors({ otp: 'OTP is required' });
      return;
    }

    setIsLoading(true);
    setErrors({});
    try {
      const response = await api.post('/auth/verify-email/', {
        email,
        otp
      });

      if (response.status === 200) {
        setSuccessMessage('Email verified successfully! Redirecting to login...');
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setIsLoading(true);
    setErrors({});
    try {
      const response = await api.post('/auth/register/', {
        username,
        email,
        password,
        password2: confirmPassword,
        resend_otp: true
      });
      
      if (response.status === 200) {
        setSuccessMessage('New OTP has been sent to your email');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthFormWrapper 
      title={showOtpField ? "Verify Email" : "Create an account"} 
      subtitle={showOtpField ? "Enter the OTP sent to your email" : "Join our community today"}
    >
      {successMessage && (
        <div className="mt-4 text-sm text-green-600 bg-green-50 p-3 rounded-md border border-green-200">
          {successMessage}
        </div>
      )}
      
      {errors.general && (
        <div className="mt-4 text-sm text-red-600 bg-red-50 p-3 rounded-md border border-red-200">
          {errors.general}
        </div>
      )}
      
      {!showOtpField ? (
        // Registration Form
        <form className="mt-8 space-y-6" onSubmit={handleRegister}>
          <div>
            <StyledInput
              icon={User}
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Username"
              error={errors.username}
              required
            />
            <StyledInput
              icon={Mail}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email address"
              error={errors.email}
              required
            />
            <StyledInput
              icon={Lock}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              error={errors.password}
              required
            />
            <StyledInput
              icon={Lock}
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm password"
              error={errors.password2}
              required
            />
          </div>
          
          <div>
            <StyledButton type="submit" isLoading={isLoading}>
              Register
              <ArrowRight className="ml-2 h-4 w-4" />
            </StyledButton>
          </div>
        </form>
      ) : (
        // OTP Verification Form
        <form className="mt-8 space-y-6" onSubmit={handleVerifyOtp}>
          <div>
            <StyledInput
              icon={Shield}
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP"
              error={errors.otp}
              required
            />
          </div>
          
          <div className="flex flex-col space-y-4">
            <StyledButton type="submit" isLoading={isLoading}>
              Verify Email
              <ArrowRight className="ml-2 h-4 w-4" />
            </StyledButton>
            <button
              type="button"
              onClick={handleResendOtp}
              className="text-sm text-indigo-600 hover:text-indigo-500"
              disabled={isLoading}
            >
              Resend OTP
            </button>
          </div>
        </form>
      )}
      
      <div className="text-center mt-6">
        <Link to="/login" className="text-sm font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out">
          Already have an account? Sign in
        </Link>
      </div>
    </AuthFormWrapper>
  );
};

export default RegisterScreen;