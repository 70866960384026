import React, { useState, useRef, useEffect } from "react";
import { Camera, RefreshCw, X } from "lucide-react";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import useTranspileStream from "../../hooks/useTranspileStream";
import { useUploadVideoMutation } from "../../api/apiSlice";
import { Toast } from '@chakra-ui/react';


// First, let's create an improved QuestionDisplay component
const QuestionDisplay = ({ question, transcription, isRecording, interimTranscript }) => (
  <div className="absolute top-0 left-0 right-0">
    {/* Question Display */}
    <div className="w-full bg-black/40 backdrop-blur-[2px] px-4 py-3">
      <div className="max-w-xl mx-auto flex items-start gap-3">
        <div className="flex-shrink-0 mt-1">
          <svg 
            className="w-4 h-4 text-white/90" 
            fill="none" 
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
            />
          </svg>
        </div>
        <div className="flex-1">
          <h3 className="text-white/60 text-xs font-medium mb-1">Current Question:</h3>
          <p className="text-white text-sm font-medium leading-relaxed">
            {question}
          </p>
        </div>
      </div>
    </div>

    {/* Transcription Display */}
    {(transcription || isRecording) && (
      <div className="w-full bg-black/40 backdrop-blur-[2px] px-4 py-3 border-t border-white/10">
        <div className="max-w-xl mx-auto flex items-start gap-3">
          <div className="flex-shrink-0 mt-1">
            <svg
              className="w-4 h-4 text-white/90"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
              />
            </svg>
          </div>
          <div className="flex-1">
            <h3 className="text-white/60 text-xs font-medium mb-1">Transcription:</h3>
            <p className="text-white text-sm font-medium leading-relaxed">
              {transcription || "Listening..."}
              {isRecording && interimTranscript && (
                <span className="text-white/60 italic ml-1">
                  {interimTranscript}
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    )}
  </div>
);


export const VideoRecorder = ({ onVideoComplete, onClose, onUploadStart, currentQuestion }) => {
  // Recording and camera state
  const [isRecording, setIsRecording] = useState(false);
  const [isFrontCamera, setIsFrontCamera] = useState(true);
  const [stream, setStream] = useState(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [hasRecordedVideo, setHasRecordedVideo] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  
  const [uploadVideoMutation, { isLoading: isMutationLoading }] = useUploadVideoMutation();
  const isUploading = isMutationLoading;

  // Get transcription functionality from the hook
  const {
    startTranscription,
    stopTranscription,
    interimTranscript,
    finalTranscript,
    setFinalTranscript,
  } = useTranspileStream();

  // Refs for managing media resources
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const timerRef = useRef(null);
  const audioStreamRef = useRef(null);

  // Initialize or reinitialize camera when camera direction changes
  const initializeCamera = async () => {
    try {
      // Clean up existing streams
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
  
      // First request audio permission separately for iOS
      await navigator.mediaDevices.getUserMedia({ audio: true });
  
      // Then get new media stream with selected camera AND audio
      const newStream = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: isFrontCamera ? "user" : "environment",
          width: { ideal: 854 },
          height: { ideal: 480 },
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          autoGainControl: true,
        },
      });
  
      setStream(newStream);
      if (videoRef.current) {
        videoRef.current.srcObject = newStream;
        await videoRef.current.play().catch((error) => {
          console.error("Error playing video:", error);
        });
      }
    } catch (error) {
      console.error("Error initializing camera:", error);
    }
  };

  // Add these useEffects to track transcription state
  useEffect(() => {
    console.log("Interim transcript updated:", interimTranscript);
  }, [interimTranscript]);

  useEffect(() => {
    console.log("Final transcript updated:", finalTranscript);
  }, [finalTranscript]);

  // Initialize camera on mount and when camera direction changes
  useEffect(() => {
    initializeCamera();

    // Cleanup function
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      if (audioStreamRef.current) {
        audioStreamRef.current.getTracks().forEach((track) => track.stop());
      }
      stopTranscription();
      clearInterval(timerRef.current);
    };
  }, [isFrontCamera]);

  // Modify startRecording to include more logging
  const startRecording = async () => {
    if (!stream || isRecording) return;
  
    try {
      console.log("Starting recording and transcription");
      setFinalTranscript("");
      recordedChunksRef.current = [];
  
      // Get audio track for transcription
      const audioTrack = stream.getAudioTracks()[0];
      console.log("Audio track available:", !!audioTrack);
  
      if (audioTrack) {
        const audioStream = new MediaStream([audioTrack]);
        console.log("Starting transcription");
        startTranscription(audioStream);
      } else {
        console.warn("No audio track available");
      }
  
      // Initialize and start video recording - This part was missing!
      const getSupportedMimeType = () => {
        const types = [
          'video/webm',
          'video/mp4',
          'video/webm;codecs=vp8,opus',
          'video/mp4;codecs=h264,aac'
        ];
        for (const type of types) {
          if (MediaRecorder.isTypeSupported(type)) {
            return type;
          }
        }
        return '';
      };
      
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: getSupportedMimeType(),
        videoBitsPerSecond: 1000000
      });
  
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };
  
      // Set up media recorder and start it
      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.start(1000);
      setIsRecording(true);
      setHasRecordedVideo(false);
      setPreviewUrl(null);
  
      // Start the recording duration timer
      timerRef.current = setInterval(() => {
        setRecordingDuration(prev => prev + 1);
      }, 1000);
  
    } catch (error) {
      console.error("Error in startRecording:", error);
      stopTranscription();
    }
  };

  const stopRecording = async () => {
    if (!mediaRecorderRef.current || !isRecording) return;

    try {
      console.log("Stopping recording and transcription");
      // Stop transcription first to ensure we get the final transcript
      stopTranscription();

      // Then stop recording
      clearInterval(timerRef.current);
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setRecordingDuration(0);

      const recordedBlob = new Blob(recordedChunksRef.current, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(recordedBlob);
      setPreviewUrl(url);
      setHasRecordedVideo(true);

      console.log("Final transcript after stopping:", finalTranscript);
    } catch (error) {
      console.error("Error stopping recording:", error);
    }
  };

  const sendVideo = async () => {
    if (!hasRecordedVideo || recordedChunksRef.current.length === 0) return;

    try {
      const recordedBlob = new Blob(recordedChunksRef.current, {
        type: "video/webm",
      });

      const videoFile = new File(
        [recordedBlob],
        `recorded-video-${Date.now()}.webm`,
        {
          type: "video/webm",
        }
      );

      // Notify about upload start
      onUploadStart?.();

      // Close the modal immediately
      onClose();

      // Use RTK Query mutation to upload
      const result = await uploadVideoMutation({
        video: videoFile,
        transcript: finalTranscript.trim()
      }).unwrap();

      // Call onVideoComplete with the response data
      onVideoComplete(result.url, result.transcript);

    } catch (error) {
      console.error("Error in video upload:", error);
      Toast({
        title: "Upload Failed",
        description: "Failed to upload video. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  // Toggle between front and back camera
  const toggleCamera = async () => {
    if (isRecording) return;
    setIsFrontCamera((prev) => !prev);
  };

  // Format recording duration for display
  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  const cleanup = () => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
    }
    setStream(null);
    setIsRecording(false);
    setRecordingDuration(0);
    setHasRecordedVideo(false);
    setPreviewUrl(null);
  };

  const handleClose = () => {
    cleanup();
    onClose?.();
  };

  return (
    <div className="fixed inset-0 bg-black flex flex-col items-center z-50">
      <div className="relative w-full h-full">
        {/* Live video preview */}
        <video
          ref={videoRef}
          autoPlay
          playsInline
          muted
          className="w-full h-full object-cover"
        />

        <QuestionDisplay 
          question={currentQuestion}
          transcription={finalTranscript}
          isRecording={isRecording}
          interimTranscript={interimTranscript}
        />

        {/* Recorded video preview */}
        {previewUrl && (
          <video
            src={previewUrl}
            autoPlay
            loop
            playsInline
            className="absolute inset-0 w-full h-full object-cover"
          />
        )}

        {/* Controls overlay */}
        <div className="absolute bottom-0 left-0 right-0 p-6 bg-gradient-to-t from-black/70 to-transparent">
          <div className="flex items-center justify-between max-w-md mx-auto">
            {/* Close button */}
            <button
              onClick={handleClose}
              className="p-3 rounded-full bg-white/20 text-white hover:bg-white/30"
              disabled={isRecording || isUploading}
            >
              <X size={24} />
            </button>

            {/* Record/Stop/Send button */}
            {!hasRecordedVideo ? (
              <button
                onClick={isRecording ? stopRecording : startRecording}
                disabled={isUploading}
                className={`w-16 h-16 rounded-full flex items-center justify-center ${
                  isRecording ? "bg-red-500 scale-110" : "bg-[#f59e0b]"
                } transition-all duration-200`}
              >
                <div
                  className={`w-12 h-12 rounded-full ${
                    isRecording ? "bg-red-700" : "bg-[#f59e0b]"
                  }`}
                />
              </button>
            ) : (
              <button
                onClick={sendVideo}
                disabled={isUploading}
                className="px-6 py-3 rounded-full bg-[#f59e0b] text-white font-medium hover:bg-[#e68a00]"
              >
                Send Video
              </button>
            )}

            {/* Camera switch button */}
            <button
              onClick={toggleCamera}
              className="p-3 rounded-full bg-white/20 text-white hover:bg-white/30"
              disabled={isRecording || isUploading}
            >
              <RefreshCw size={24} />
            </button>
          </div>

          {/* Status display */}
          <div className="mt-4 text-center text-white">
            {isRecording && (
              <div className="flex items-center justify-center space-x-2">
                <div className="w-3 h-3 rounded-full bg-red-500 animate-pulse" />
                <span>{formatDuration(recordingDuration)}</span>
              </div>
            )}
            {isUploading && (
              <div className="flex items-center justify-center space-x-2">
                <div className="w-4 h-4">
                  <RefreshCw className="animate-spin" size={16} />
                </div>
                <span>Uploading video...</span>
              </div>
            )}
            {hasRecordedVideo && !isUploading && <p>Ready to send!</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
