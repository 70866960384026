// App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ChatUI from "./components/RealtimeTranscription";
import LoginScreen from "./components/auth/loginScreen";
import RegisterScreen from "./components/auth/registerScreen";
import PasswordResetRequestScreen from "./components/auth/passwordResetRequestScreen";
import PasswordResetConfirmScreen from "./components/auth/passwordResetConfirmScreen";
import ChangePasswordScreen from "./components/auth/changePasswordScreen";
import DeleteAccountScreen from "./components/auth/deleteAccountScreen";
import DashboardScreen from "./components/dashboard/dashboardScreen";
import CreateProjectScreen from "./components/dashboard/createProjectScreen";
import Header from "./components/header/Header";
import ProjectDetails from "./components/Projects/ProjectDetails/ProjectDetails";
import { LanguageProvider } from "./utils/LanguageContext";
import ChatPage from "./components/chat/ChatPage";
import MyAccount from "./components/account/MyAccount";
import { ChakraProvider } from "@chakra-ui/react";
import NewChat from "./components/chat/NewChat";
import ResetPassword from "./components/account/ResetPassword";
import ScopeOfWorkForm from "./components/Projects/ProjectDetails/ScopeOfWorkForm";
import { useLocation } from "react-router-dom";
import { PublicDocumentViewer, QRRedirect, PublicDocumentError } from "./components/Projects/ProjectDetails/public-document-viewer";

export const isTokenValid = () => {
  const token = localStorage.getItem('accessToken');
  
  if (!token) return false;
  
  try {
    const tokenPayload = JSON.parse(atob(token.split('.')[1]));
    const expiryTime = tokenPayload.exp * 1000;
    const currentTime = Date.now();
    
    return currentTime < expiryTime;
  } catch (error) {
    console.error('Error parsing token:', error);
    return false;
  }
};

export const isAuthenticated = () => {
  return isTokenValid();
};

// AuthWrapper for login/register routes
export const AuthWrapper = ({ children }) => {
  const location = useLocation();
  
  // If user has valid token and tries to access login/register pages,
  // redirect them to dashboard
  if (isAuthenticated() && 
      ['/login', '/register', '/password-reset', '/reset-password', '/resetPassword'].includes(location.pathname)) {
    return <Navigate to="/dashboard" replace />;
  }
  
  return children;
};

// Protected route component
const ProtectedRoute = ({ element: Element, ...rest }) => {
  const location = useLocation();
  
  if (!isAuthenticated()) {
    // Store the attempted URL
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <>
      <Header />
      <Element {...rest} />
    </>
  );
};

function App() {
  return (
    <ChakraProvider>
      <LanguageProvider>
        <Router>
          <div className={`App`}>
            <Routes>
              {/* Public routes wrapped with AuthWrapper */}
              <Route 
                path="/login" 
                element={
                  <AuthWrapper>
                    <LoginScreen />
                  </AuthWrapper>
                } 
              />
              <Route 
                path="/resetPassword" 
                element={
                  <AuthWrapper>
                    <ResetPassword />
                  </AuthWrapper>
                } 
              />
              <Route 
                path="/register" 
                element={
                  <AuthWrapper>
                    <RegisterScreen />
                  </AuthWrapper>
                } 
              />
              <Route
                path="/password-reset"
                element={
                  <AuthWrapper>
                    <PasswordResetRequestScreen />
                  </AuthWrapper>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <AuthWrapper>
                    <PasswordResetConfirmScreen />
                  </AuthWrapper>
                }
              />

                            {/* Public document routes - Note: These should be BEFORE protected routes */}
              <Route 
                path="/api/documents/redirect/:projectId" 
                element={<QRRedirect />}
              />
              <Route 
                path="/documents/public/:uuid" 
                element={<PublicDocumentViewer />}
              />
              <Route
                path="/document-error"
                element={<PublicDocumentError />}
              />


              {/* Protected routes */}
              <Route
                path="/chat/:id"
                element={<ProtectedRoute element={NewChat} />}
              />
              <Route
                path="/bid-report/:id"
                element={
                  <ProtectedRoute element={ScopeOfWorkForm} />}
              />

              <Route
                path="/change-password"
                element={<ProtectedRoute element={ChangePasswordScreen} />}
              />
              <Route
                path="/delete-account"
                element={<ProtectedRoute element={DeleteAccountScreen} />}
              />
              <Route
                path="/myAccount"
                element={<ProtectedRoute element={MyAccount} />}
              />
              <Route
                path="/dashboard"
                element={<ProtectedRoute element={DashboardScreen} />}
              />
              <Route
                path="/chatss"
                element={<ProtectedRoute element={ChatPage} />}
              />
              <Route
                path="/project/:id"
                element={<ProtectedRoute element={ProjectDetails} />}
              />
              <Route
                path="/create-project"
                element={<ProtectedRoute element={CreateProjectScreen} />}
              />

              {/* Default route - redirect to dashboard if authenticated, otherwise to login */}
              <Route 
                path="/" 
                element={
                  isAuthenticated() ? 
                    <Navigate replace to="/dashboard" /> : 
                    <Navigate replace to="/login" />
                } 
              />

              {/* Catch-all route */}
              <Route 
                path="*" 
                element={
                  isAuthenticated() ? 
                    <Navigate to="/dashboard" /> : 
                    <Navigate to="/login" />
                } 
              />
            </Routes>
          </div>
        </Router>
      </LanguageProvider>
    </ChakraProvider>
  );
}

export default App;