import React, { useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import {
  Calendar,
  Maximize2,
  Bed,
  Bath,
  Home,
  Clock,
  Loader2,
  ClipboardList,
  FileText,
  MessageCircle,
  Trash2,
  Pencil,
  Star,
  Palette,
} from "lucide-react";

import useHandleSessionExpired from "../../../utils/useHandleSessionExpired";
import createApiClient from "../../../api/apiClient";
import {
  useGetProjectByIdQuery,
  useGetChatHistoryQuery,
} from "../../../api/apiSlice";

const ProjectDetailsSkeleton = () => {
  return (
    <div className="flex flex-col h-screen bg-white pt-[88px] md:pt-[100px]">
      <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-sm mb-6 animate-pulse">
        {/* Header Skeleton */}
        <div className="border-b border-gray-200 p-4 md:p-6">
          <div className="flex flex-col gap-4 md:gap-6">
            {/* Title Skeleton */}
            <div className="h-8 md:h-10 bg-gray-200 rounded-md w-3/4"></div>

            {/* Action Buttons and Progress Skeleton */}
            <div className="flex flex-wrap items-center gap-3 md:gap-4">
              <div className="h-10 w-32 bg-gray-200 rounded-md"></div>
              <div className="h-10 w-32 bg-gray-200 rounded-md"></div>
              <div className="h-20 w-20 rounded-full bg-gray-200"></div>
            </div>

            {/* Metadata Skeleton */}
            <div className="flex flex-wrap items-center gap-3 md:gap-4">
              <div className="h-5 w-32 bg-gray-200 rounded-md"></div>
              <div className="h-5 w-32 bg-gray-200 rounded-md"></div>
            </div>
          </div>
        </div>

        {/* Tab Navigation Skeleton */}
        <div className="border-b border-gray-200">
          <div className="flex gap-2 p-2">
            <div className="h-10 w-32 bg-gray-200 rounded-md"></div>
            <div className="h-10 w-32 bg-gray-200 rounded-md"></div>
          </div>
        </div>

        {/* Content Area Skeleton */}
        <div className="p-4 md:p-6">
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6">
            {/* Property Detail Cards Skeleton */}
            {[...Array(6)].map((_, index) => (
              <div key={index} className="bg-gray-50 p-3 md:p-4 rounded-lg">
                <div className="flex items-center gap-2 mb-2">
                  <div className="h-5 w-5 bg-gray-200 rounded"></div>
                  <div className="h-4 w-24 bg-gray-200 rounded"></div>
                </div>
                <div className="h-6 w-20 bg-gray-200 rounded"></div>
              </div>
            ))}
          </div>

          {/* Renovation Details Skeleton (hidden initially) */}
          <div className="hidden space-y-6 mt-6">
            {/* Phase Navigation Skeleton */}
            <div className="flex flex-wrap gap-2">
              {[...Array(3)].map((_, index) => (
                <div
                  key={index}
                  className="h-10 w-24 bg-gray-200 rounded-md"
                ></div>
              ))}
            </div>

            {/* Phase Content Skeleton */}
            {[...Array(2)].map((_, index) => (
              <div
                key={index}
                className="border border-gray-200 rounded-lg overflow-hidden"
              >
                <div className="border-b border-gray-200 bg-gray-50 px-4 py-3">
                  <div className="h-6 w-48 bg-gray-200 rounded"></div>
                </div>
                <div className="p-4 space-y-4">
                  <div className="h-24 bg-gray-200 rounded-lg"></div>
                  <div className="h-24 bg-gray-200 rounded-lg"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectDetails = () => {
  const navigate = useNavigate();
  const handleSessionExpired = useHandleSessionExpired();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("details");
  const [selectedPhase, setSelectedPhase] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [editValue, setEditValue] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const { t } = useTranslation();

  // Initialize API client with session expiration handler
  const apiClient = React.useMemo(
    () => createApiClient(() => handleSessionExpired()),
    [handleSessionExpired]
  );

  const {
    data: projectData,
    isLoading: projectLoading,
    error: projectError,
    refetch: refetchProject,
  } = useGetProjectByIdQuery(id);

  const {
    data: chatHistory,
    isLoading: chatLoading,
    error: chatError,
    refetch: refetchChat,
  } = useGetChatHistoryQuery(id);

  const handleUpdateMessage = useCallback(
    async (item) => {
      setIsUpdating(true);
      try {
        const response = await apiClient.put("history-update/", {
          id: id,
          phase: item.phase,
          item: item.item,
          message: {
            content: editValue,
            url: item.message.url,
            loop_index: item.loop_number || 1,
          },
        });

        if (response.status === 200) {
          // Refresh both project and chat data
          await Promise.all([refetchProject(), refetchChat()]);
          setEditingMessage(null);
          setEditValue("");
        }
      } catch (error) {
        if (error.response?.status === 401) {
          // Session expired handling is automatic through apiClient
          return;
        }
        console.error("Error updating message:", error);
      } finally {
        setIsUpdating(false);
      }
    },
    [id, editValue, apiClient, refetchProject, refetchChat]
  );

  const handleDeleteProject = async () => {
    setIsDeleting(true);
    try {
      const response = await apiClient.delete(`projects/${id}/`);

      if (response.status === 200 || response.status === 204) {
        setIsDeleteModalOpen(false);
        navigate("/dashboard");
      }
    } catch (error) {
      if (error.response?.status === 401) {
        // Session expired handling is automatic through apiClient
        return;
      }
      console.error("Error deleting project:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  if (projectLoading || chatLoading) {
    return <ProjectDetailsSkeleton />;
  }

  if (projectError || chatError) {
    return (
      <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded m-4">
        {projectError?.message ||
          chatError?.message ||
          "Error loading project details"}
      </div>
    );
  }

  const phases = [...new Set(chatHistory?.history?.map((item) => item.phase))];
  const currentPhaseItems = chatHistory?.history?.filter(
    (item) => item.phase === selectedPhase
  );

  const completionPercentage = Math.round(
    chatHistory?.completion_stats?.completion_percentage || 0
  );

  return (
    <div className="flex flex-col h-screen bg-white pt-[88px] md:pt-[100px]">
      {/* Project Header with Progress */}
      <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-sm mb-6">
        <div className="border-b border-gray-200 p-4 md:p-6">
          <div className="flex flex-col gap-4 md:gap-6">
            {/* Project Title */}
            <h1 className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-900 break-words">
              {projectData?.name || t("untitledProject")}
            </h1>

            {/* Action Buttons and Progress Circle Row */}
            <div className="flex flex-wrap items-center gap-3 md:gap-4">
              <button
                onClick={() =>
                  navigate(`/chat/${id}`, { state: { projectData } })
                }
                className="inline-flex items-center justify-center gap-2 px-3 md:px-5 py-2 md:py-2.5 text-sm 
              bg-orange-50 text-orange-600 rounded-md hover:bg-orange-100 transition-colors"
                aria-label="Open Chat Assistant"
              >
                <MessageCircle className="h-4 w-4 md:h-5 md:w-5 flex-shrink-0" />
                <span className="hidden sm:inline">{t("chatAssistant")}</span>
                <span className="sm:hidden">{t("chatMobile")}</span>
              </button>

              <button
                onClick={() => setIsDeleteModalOpen(true)}
                className="inline-flex items-center justify-center gap-2 px-3 md:px-5 py-2 md:py-2.5 text-sm 
              bg-red-50 text-red-600 rounded-md hover:bg-red-100 transition-colors"
                aria-label="Delete Project"
              >
                <Trash2 className="h-4 w-4 md:h-5 md:w-5 flex-shrink-0" />
                <span className="hidden sm:inline">{t("deleteProject")}</span>
                <span className="sm:hidden">{t("deleteMobile")}</span>
              </button>

              <button
                onClick={() => navigate(`/bid-report/${id}`)}
                className="inline-flex items-center justify-center gap-2 px-3 md:px-5 py-2 md:py-2.5 text-sm 
              bg-orange-500 text-white rounded-md hover:bg-orange-300 transition-colors"
              >
                <FileText className="h-4 w-4" />
                Bid Report
              </button> 

              {/* Progress Circle */}
              <div className="relative inline-flex items-center justify-center">
                <svg className="w-20 h-20">
                  <circle
                    className="text-gray-200"
                    strokeWidth="5"
                    stroke="currentColor"
                    fill="transparent"
                    r="30"
                    cx="40"
                    cy="40"
                  />
                  <circle
                    className="text-orange-500"
                    strokeWidth="5"
                    strokeLinecap="round"
                    stroke="currentColor"
                    fill="transparent"
                    r="30"
                    cx="40"
                    cy="40"
                    style={{
                      strokeDasharray: `${2 * Math.PI * 30}`,
                      strokeDashoffset: `${
                        2 * Math.PI * 30 * (1 - completionPercentage / 100)
                      }`,
                      transform: "rotate(-90deg)",
                      transformOrigin: "center",
                    }}
                  />
                </svg>
                <span className="absolute text-xl font-bold text-gray-700">
                  {completionPercentage}%
                </span>
              </div>
            </div>

            {/* Metadata */}
            <div className="flex flex-wrap items-center gap-3 md:gap-4 text-xs md:text-sm text-gray-500">
              <div className="inline-flex items-center gap-1.5 md:gap-2">
                <Calendar className="h-3 w-3 md:h-4 md:w-4 flex-shrink-0" />
                <span>
                  {t("created")}{" "}
                  {projectData?.date_created
                    ? format(new Date(projectData.date_created), "MMM dd, yyyy")
                    : t("na")}
                </span>
              </div>
              <div className="inline-flex items-center gap-1.5 md:gap-2">
                <Clock className="h-3 w-3 md:h-4 md:w-4 flex-shrink-0" />
                <span>
                  {t("lastModified")}{" "}
                  {projectData?.last_modified
                    ? format(
                        new Date(projectData.last_modified),
                        "MMM dd, yyyy"
                      )
                    : t("na")}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Tab Navigation */}
        <div className="border-b border-gray-200">
          <div className="flex">
            <button
              onClick={() => setActiveTab("details")}
              className={`flex items-center gap-2 px-6 py-4 font-medium text-sm border-b-2 transition-colors
            ${
              activeTab === "details"
                ? "border-orange-500 text-orange-600"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            >
              <FileText className="h-4 w-4" />
              {t("projectDetails")}
            </button>
            <button
              onClick={() => setActiveTab("renovation")}
              className={`flex items-center gap-2 px-6 py-4 font-medium text-sm border-b-2 transition-colors
            ${
              activeTab === "renovation"
                ? "border-orange-500 text-orange-600"
                : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
            }`}
            >
              <ClipboardList className="h-4 w-4" />
              {t("renovationDetails")}
            </button>
          </div>
        </div>

        {/* Tab Content */}
        {activeTab === "details" ? (
          <div className="p-4 md:p-6">
            {/* Main Property Details Grid */}
            <div className="grid grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6">
              <div className="bg-gray-50 p-3 md:p-4 rounded-lg hover:shadow-md transition-shadow">
                <div className="flex items-center gap-2 mb-1 md:mb-2">
                  <Calendar className="h-4 w-4 md:h-5 md:w-5 text-orange-500" />
                  <span className="text-sm md:text-base text-gray-600">
                    {t("yearBuilt")}
                  </span>
                </div>
                <p className="text-base md:text-lg font-medium text-gray-900">
                  {projectData?.year_built || t("na")}
                </p>
              </div>

              <div className="bg-gray-50 p-3 md:p-4 rounded-lg hover:shadow-md transition-shadow">
                <div className="flex items-center gap-2 mb-1 md:mb-2">
                  <Maximize2 className="h-4 w-4 md:h-5 md:w-5 text-orange-500" />
                  <span className="text-sm md:text-base text-gray-600">
                    {t("squareFootage")}
                  </span>
                </div>
                <p className="text-base md:text-lg font-medium text-gray-900">
                  {projectData?.square_footage
                    ? `${projectData.square_footage} sq ft`
                    : t("na")}
                </p>
              </div>

              <div className="bg-gray-50 p-3 md:p-4 rounded-lg hover:shadow-md transition-shadow">
                <div className="flex items-center gap-2 mb-1 md:mb-2">
                  <Bed className="h-4 w-4 md:h-5 md:w-5 text-orange-500" />
                  <span className="text-sm md:text-base text-gray-600">
                    {t("bedrooms")}
                  </span>
                </div>
                <p className="text-base md:text-lg font-medium text-gray-900">
                  {projectData?.total_bedrooms || t("na")}
                </p>
              </div>

              <div className="bg-gray-50 p-3 md:p-4 rounded-lg hover:shadow-md transition-shadow">
                <div className="flex items-center gap-2 mb-1 md:mb-2">
                  <Bath className="h-4 w-4 md:h-5 md:w-5 text-orange-500" />
                  <span className="text-sm md:text-base text-gray-600">
                    {t("bathrooms")}
                  </span>
                </div>
                <p className="text-base md:text-lg font-medium text-gray-900">
                  {projectData?.total_bathrooms || t("na")}
                </p>
              </div>

              <div className="bg-gray-50 p-3 md:p-4 rounded-lg hover:shadow-md transition-shadow">
                <div className="flex items-center gap-2 mb-1 md:mb-2">
                  <Home className="h-4 w-4 md:h-5 md:w-5 text-orange-500" />
                  <span className="text-sm md:text-base text-gray-600">
                    {t("propertyType")}
                  </span>
                </div>
                <p className="text-base md:text-lg font-medium text-gray-900">
                  {projectData?.property_type || t("na")}
                </p>
              </div>

              {/* Additional Details Grid */}
              <div className="bg-gray-50 p-3 md:p-4 rounded-lg hover:shadow-md transition-shadow">
                <div className="flex items-center gap-2 mb-1 md:mb-2">
                  <Star className="h-4 w-4 md:h-5 md:w-5 text-orange-500" />
                  <span className="text-sm md:text-base text-gray-600">
                    {t("qualityGrade")}
                  </span>
                </div>
                <p className="text-base md:text-lg font-medium text-gray-900 mt-1">
                  {projectData?.quality || t("na")}
                </p>
              </div>

              <div className="bg-gray-50 p-3 md:p-4 rounded-lg hover:shadow-md transition-shadow">
                <div className="flex items-center gap-2 mb-1 md:mb-2">
                  <Pencil className="h-4 w-4 md:h-5 md:w-5 text-orange-500" />
                  <span className="text-sm md:text-base text-gray-600">
                    {t("colorTemplate")}
                  </span>
                </div>
                <p className="text-base md:text-lg font-medium text-gray-900 mt-1">
                  {projectData?.color_template || t("na")}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-6">
            {/* Phase Navigation */}
            <div className="flex flex-wrap gap-2 mb-6">
              {phases?.map((phase) => (
                <button
                  key={phase}
                  onClick={() => setSelectedPhase(phase)}
                  className={`px-4 py-2 rounded-md text-sm font-medium transition-colors
                    ${
                      selectedPhase === phase
                        ? "bg-orange-500 text-white shadow-md"
                        : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                    }`}
                >
                  {phase.replace(/_/g, " ")}
                </button>
              ))}
            </div>

            {/* Phase Content */}
            <div className="space-y-6">
              {currentPhaseItems?.map((item, index) => (
                <div
                  key={`${item.phase}-${item.item}-${index}`}
                  className="bg-white border border-gray-200 rounded-lg overflow-hidden hover:shadow-md transition-shadow"
                >
                  <div className="border-b border-gray-200 bg-gray-50 px-4 py-3">
                    <h3 className="text-lg font-medium text-gray-900 capitalize">
                      {item.item.replace(/_/g, " ")}
                    </h3>
                  </div>

                  <div className="p-4 space-y-4">
                    {item.question.question?.prompt && (
                      <div className="bg-gray-50 p-3 rounded-lg">
                        <p className="text-sm font-medium text-orange-600 mb-1">
                          {t("question")}
                        </p>
                        <p className="text-gray-800">
                          {item.question.question.prompt}
                        </p>
                      </div>
                    )}

                    <div className="bg-orange-50 p-3 rounded-lg">
                      <div className="flex justify-between items-start">
                        <p className="text-sm font-medium text-orange-600 mb-1">
                          {t("response")}
                        </p>
                        {editingMessage !== index && (
                          <button
                            onClick={() => {
                              setEditingMessage(index);
                              setEditValue(item.message?.content || "");
                            }}
                            className="text-gray-500 hover:text-orange-600 transition-colors"
                          >
                            <Pencil className="h-4 w-4" />
                          </button>
                        )}
                      </div>

                      {editingMessage === index ? (
                        <div className="space-y-2">
                          <textarea
                            value={editValue}
                            onChange={(e) => setEditValue(e.target.value)}
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
                            rows={3}
                          />
                          <div className="flex justify-end gap-2">
                            <button
                              onClick={() => {
                                setEditingMessage(null);
                                setEditValue("");
                              }}
                              className="px-3 py-1 text-sm text-gray-600 hover:text-gray-800"
                              disabled={isUpdating}
                            >
                              {t("cancel")}
                            </button>
                            <button
                              onClick={() => handleUpdateMessage(item)}
                              className="px-3 py-1 text-sm bg-orange-500 text-white rounded-md hover:bg-orange-600 disabled:opacity-50"
                              disabled={isUpdating}
                            >
                              {isUpdating ? (
                                <div className="flex items-center gap-2">
                                  <Loader2 className="h-4 w-4 animate-spin" />
                                  {t("saving")}
                                </div>
                              ) : (
                                t("save")
                              )}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <p className="text-gray-800">{item.message?.content}</p>
                      )}
                    </div>

                    {item.message?.url && (
                      <div className="mt-2">
                        <p className="text-sm font-medium text-orange-600 mb-2">
                          {t("attachedMedia")}
                        </p>
                        <video
                          controls
                          className="w-full rounded-lg border border-gray-200"
                          src={item.message.url}
                        >
                          {t("browserVideoError")}
                        </video>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">
              {t("deleteProjectTitle")}
            </h3>
            <p className="text-gray-600 mb-6">{t("deleteProjectConfirm")}</p>
            <div className="flex justify-end gap-4">
              <button
                className="px-4 py-2 text-gray-600 hover:text-gray-800 font-medium"
                onClick={() => setIsDeleteModalOpen(false)}
                disabled={isDeleting}
              >
                {t("cancel")}
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 font-medium transition-colors disabled:opacity-50"
                onClick={handleDeleteProject}
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <div className="flex items-center gap-2">
                    <Loader2 className="h-4 w-4 animate-spin" />
                    {t("deleting")}
                  </div>
                ) : (
                  t("delete")
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
