import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const useHandleSessionExpired = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const handleSessionExpired = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    let isTokenRefreshed = false;
    toast({
      title: "Session Expired",
      description: "Your session has expired. Please login again.",
      status: "warning",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    });
    setTimeout(() => {
      navigate("/login");
    }, 1500);

    // if (refreshToken) {
    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_API_URL}/auth/refresh-token/`,
    //       { refresh: refreshToken }
    //     );

    //     if (response.data && response.data.access) {
    //       localStorage.setItem("accessToken", response.data.access);
    //       if (response.data.refresh) {
    //         localStorage.setItem("refreshToken", response.data.refresh);
    //       }
    //       isTokenRefreshed = true;
    //       toast({
    //         title: "Session refreshed",
    //         description: "Your session has been refreshed. Please refresh & try again.",
    //         status: "warning",
    //         duration: 3000,
    //         isClosable: true,
    //         position: "top-right",
    //       });
    //       return;
    //     }
    //   } catch (error) {
    //     console.error("Error refreshing token:", error);
    //     window.location.reload();
    //   }
    // }
  };

  return handleSessionExpired;
};

export default useHandleSessionExpired;
