import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowUp, Video, Loader, X, Mic } from "lucide-react";
import createApiClient from "../../api/apiClient";
import { useGetChatHistoryQuery } from "../../api/apiSlice";

import {
  AIIcon,
  ThinkingAIIcon,
  ThinkingIndicator,
  VoiceTranscription,
  ScopeConfirmationModal,
  VideoUploadStatus,
} from "./ChatComponents";
import { VideoRecorder } from "./VideoLayout";
import useHandleSessionExpired from "../../utils/useHandleSessionExpired";
import { useTranslation } from "react-i18next";
import ChatSkeleton from "./ChatSkeleton";

const getCompletionColor = (percentage) => {
  return `linear-gradient(to right, #86efac ${percentage}%, #f3f4f6 ${percentage}%)`;
};

const ChatInterface = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleSessionExpired = useHandleSessionExpired();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [showVideoRecorder, setShowVideoRecorder] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [qaStructure, setQaStructure] = useState({});
  const [nextQuestion, setNextQuestion] = useState(null);
  const [completionStats, setCompletionStats] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [showTransitionAlert, setShowTransitionAlert] = useState(false);

  const [filterLocation, setFilterLocation] = useState("all");
  const [uniqueLocations, setUniqueLocations] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const [isUploadingVideo, setIsUploadingVideo] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  // Create API client instance with session expiration handler
  const apiClient = React.useMemo(
    () => createApiClient(() => handleSessionExpired()),
    [handleSessionExpired]
  );

  // Use RTK Query hook for chat history
  const {
    data: historyData,
    error: historyError,
    isLoading: historyLoading,
  } = useGetChatHistoryQuery(id);

  const chatEndRef = useRef(null);
  const inputRef = useRef(null);

  // Initialize chat and load history
  useEffect(() => {
    if (historyData) {
      const { history, next_question, qa_structure, completion_stats } = historyData;

      setQaStructure(qa_structure || {});
      if (completion_stats?.completion_percentage !== undefined) {
        setCompletionStats({
          completion_percentage: parseFloat(
            completion_stats.completion_percentage
          ),
        });
      }

      // Format history
      const formattedHistory = history.flatMap((msg) => {

        // Get prompt based on current language
        const questionPrompt = t('speech') === 'es' 
          ? msg.question?.question?.prompt_spn || ""
          : msg.question?.question?.prompt || "";
        
        const userContent = msg.message?.content || "";
        const isYesNo = msg.question?.question?.expected_response === "Yes/No";
        const isTransition = msg.phase !== currentLocation;

        if (isTransition) {
          setCurrentLocation(msg.phase);
          setShowTransitionAlert(true);
        }

        return [
          {
            role: "assistant",
            content: questionPrompt,
            phase: msg.phase,
            item: msg.item,
            isQuestion: true,
            isTransition: isTransition,
            isYesNo: isYesNo,
          },
          {
            role: "user",
            content: userContent,
            url: msg.message?.url || null,
            phase: msg.phase,
            item: msg.item,
            isAnswer: true,
          },
        ];
      });

      setMessages(formattedHistory);

      // Handle next question
      if (next_question) {
        
      // With this conditional logic
      const nextPrompt = t('speech') === 'es'
        ? next_question.message?.question?.prompt_spn || ""
        : next_question.message?.question?.prompt || "";
        

        const isYesNo =
          next_question.message?.question?.expected_response === "Yes/No";
        const isTransition = next_question.phase !== currentLocation;

        if (isTransition) {
          setShowTransitionAlert(true);
          setCurrentLocation(next_question.phase);
        }

        setMessages((prev) => [
          ...prev,
          {
            role: "assistant",
            content: nextPrompt,
            phase: next_question.phase,
            item: next_question.item,
            isQuestion: true,
            isTransition: isTransition,
            isYesNo: isYesNo,
          },
        ]);
        setNextQuestion(next_question);
      }
    }
  }, [historyData, id, t('speech')]);

  // Handle history error
  useEffect(() => {
    if (historyError) {
      if (historyError.status === 401) {
        handleSessionExpired();
      }
    }
  }, [historyError]);

  useEffect(() => {
    if (historyData?.history) {
      const locations = new Set(historyData.history.map((msg) => msg.phase));
      setUniqueLocations(["all", ...Array.from(locations)]);
    }
  }, [historyData]);

  const filterMessages = (messages) => {
    if (filterLocation === "all") return messages;
    return messages.filter((msg) => msg.phase === filterLocation);
  };

  const handleSendMessage = async () => {
    setIsSending(true);

    if (!input.trim()) return;

    try {
      setIsLoading(true);
      setIsThinking(true);

      const userMessage = {
        role: "user",
        content: input,
        phase: nextQuestion?.phase || messages[messages.length - 1]?.phase,
        item: nextQuestion?.item || messages[messages.length - 1]?.item,
        isAnswer: true,
      };
      setMessages((prev) => [...prev, userMessage]);
      setInput("");

      const response = await apiClient.post("/chat-questionaire/", {
        message: input,
        phase: userMessage.phase,
        item: userMessage.item,
        id: id,
      });

      if (response.data) {
        await new Promise((resolve) => setTimeout(resolve, 500));

        // With this conditional logic
        const promptText = t('speech') === 'es'
        ? response.data.message?.question?.prompt_spn || ""
        : response.data.message?.question?.prompt || "";

        const isYesNo =
          response.data.message?.question?.expected_response === "Yes/No";
        const isTransition = response.data.phase !== currentLocation;

        if (isTransition) {
          setShowTransitionAlert(true);
          setCurrentLocation(response.data.phase);
        }

        const assistantMessage = {
          role: "assistant",
          content: promptText,
          phase: response.data.phase,
          item: response.data.item,
          isQuestion: true,
          isTransition: isTransition,
          isYesNo: isYesNo,
        };

        setMessages((prev) => [...prev, assistantMessage]);
        setNextQuestion(response.data);

        if (response.data.completion !== undefined) {
          setCompletionStats({
            completion_percentage: parseFloat(response.data.completion),
          });
          console.log(
            "Updated completion:",
            parseFloat(response.data.completion)
          ); // Debug log
        }
      }
    } catch (err) {
      console.error("Error sending message:", err);
      if (err.response?.status === 401) {
        handleSessionExpired();
      }
    } finally {
      setIsLoading(false);
      setIsThinking(false);
      setIsSending(false);
    }
  };

  const handleUpdateMessage = async () => {
    if (!editingMessage || !input.trim()) return;

    try {
      setIsLoading(true);
      setIsSending(true);

      // Get loop index from original message if it exists
      const loopIndex = editingMessage.message?.loop_index || 1;

      const payload = {
        id: id,
        phase: editingMessage.phase,
        item: editingMessage.item,
        message: {
          content: input,
          url: editingMessage.message?.url || null,
          loop_index: loopIndex,
        },
      };

      await apiClient.put("/history-update/", payload);

      // Update local messages to reflect the change
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.phase === editingMessage.phase &&
          msg.item === editingMessage.item &&
          msg.role === "user" &&
          msg.isAnswer &&
          (!msg.message?.loop_index || msg.message.loop_index === loopIndex)
            ? {
                ...msg,
                content: input,
                message: {
                  ...msg.message,
                  content: input,
                },
              }
            : msg
        )
      );

      handleCancelEdit();
    } catch (error) {
      console.error("Error updating message:", error);
      if (error.response?.status === 401) {
        handleSessionExpired();
      }
    } finally {
      setIsLoading(false);
      setIsSending(false);
    }
  };

  // Handle starting edit mode
  const handleEditMessage = (message) => {
    // Prevent editing while thinking or loading
    if (isThinking || isLoading) return;

    setEditingMessage(message);
    setIsEditing(true);

    // Set input value from message content
    const messageContent = message.content || message.message?.content || "";
    setInput(messageContent);

    // Focus and scroll to input
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  // Handle canceling edit mode
  const handleCancelEdit = () => {
    setEditingMessage(null);
    setIsEditing(false);
    setInput("");
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const handleTranscriptionComplete = async (
    transcript,
    isEditing,
    editingMessage
  ) => {
    if (!transcript.trim()) return;

    try {
      setIsLoading(true);
      setIsSending(true);

      if (isEditing && editingMessage) {
        // Handle editing with voice
        const payload = {
          id: id,
          phase: editingMessage.phase,
          item: editingMessage.item,
          message: {
            content: transcript, // Use transcript instead of input
            url: editingMessage.message?.url || null,
          },
        };

        await apiClient.put("/history-update/", payload);

        // Update local messages state
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.phase === editingMessage.phase &&
            msg.item === editingMessage.item
              ? {
                  ...msg,
                  content: transcript, // Update direct content
                  message: {
                    // Update nested message structure
                    ...msg.message,
                    content: transcript,
                  },
                }
              : msg
          )
        );

        handleCancelEdit();
      } else {
        // Handle new message with voice
        setIsThinking(true);
        const userMessage = {
          role: "user",
          content: transcript,
          phase: nextQuestion?.phase || messages[messages.length - 1]?.phase,
          item: nextQuestion?.item || messages[messages.length - 1]?.item,
          isAnswer: true,
        };

        setMessages((prev) => [...prev, userMessage]);

        const response = await apiClient.post("/chat-questionaire/", {
          message: transcript,
          phase: userMessage.phase,
          item: userMessage.item,
          id: id,
        });

        if (response.data.message) {
          // const promptText = response.data.message?.question?.prompt || "";
          
          const promptText = t('speech') === 'es'
          ? response.data.message?.question?.prompt_spn || ""
          : response.data.message?.question?.prompt || "";

          const isYesNo =
            response.data.message?.question?.expected_response === "Yes/No";
          const isTransition = response.data.phase !== currentLocation;

          if (isTransition) {
            setShowTransitionAlert(true);
            setCurrentLocation(response.data.phase);
          }

          const assistantMessage = {
            role: "assistant",
            content: promptText,
            phase: response.data.phase,
            item: response.data.item,
            isQuestion: true,
            isTransition: isTransition,
            isYesNo: isYesNo,
          };

          setMessages((prev) => [...prev, assistantMessage]);
          setNextQuestion({
            phase: response.data.phase,
            item: response.data.item,
          });
        }
      }
    } catch (error) {
      console.error("Error handling transcription:", error);
    } finally {
      setIsLoading(false);
      setIsThinking(false);
      setIsSending(false);
    }
  };

  // Updated handleVideoComplete function
  const handleVideoComplete = async (videoUrl, transcription) => {
    try {
      setIsSending(true);
      setIsLoading(true);
      setIsThinking(true);

      const userMessage = {
        role: "user",
        content: transcription || "Video Response",
        phase: nextQuestion?.phase || messages[messages.length - 1]?.phase,
        item: nextQuestion?.item || messages[messages.length - 1]?.item,
        isAnswer: true,
        url: videoUrl,
      };

      setMessages((prev) => [...prev, userMessage]);

      const response = await apiClient.post("/chat-questionaire/", {
        message: {
          content: transcription || "Video Response",
          url: videoUrl,
        },
        phase: userMessage.phase,
        item: userMessage.item,
        id: id,
      });

      if (response.data) {
        
        // With this
        const promptText = t('speech') === 'es'
          ? response.data.message?.question?.prompt_spn || ""
          : response.data.message?.question?.prompt || "";
              
        const isYesNo =
          response.data.message?.question?.expected_response === "Yes/No";
        const isTransition = response.data.phase !== currentLocation;

        if (isTransition) {
          setShowTransitionAlert(true);
          setCurrentLocation(response.data.phase);
        }

        const assistantMessage = {
          role: "assistant",
          content: promptText,
          phase: response.data.phase,
          item: response.data.item,
          isQuestion: true,
          isTransition: isTransition,
          isYesNo: isYesNo,
        };

        setMessages((prev) => [...prev, assistantMessage]);
        setNextQuestion(response.data);
      }
    } catch (error) {
      console.error("Error handling video response:", error);
    } finally {
      setIsLoading(false);
      setIsThinking(false);
      setIsSending(false);
      setUploadComplete(true);
      // Keep the upload indicator visible briefly after completion
      setTimeout(() => {
        setIsUploadingVideo(false);
        setUploadComplete(false);
      }, 1000);
    }
  };

  // Updated handleSkipQuestion function
  const handleSkipQuestion = async () => {
    try {
      setIsLoading(true);
      setIsThinking(true);
      setIsSending(true);

      const userMessage = {
        role: "user",
        content: "N/A",
        phase: nextQuestion?.phase || messages[messages.length - 1]?.phase,
        item: nextQuestion?.item || messages[messages.length - 1]?.item,
        isAnswer: true,
      };
      setMessages((prev) => [...prev, userMessage]);

      const response = await apiClient.post("/chat-questionaire/", {
        message: "N/A",
        phase: userMessage.phase,
        item: userMessage.item,
        id: id,
      });

      if (response.data) {
        await new Promise((resolve) => setTimeout(resolve, 500));

        // const promptText = response.data.message?.question?.prompt || "";

        const promptText = t('speech') === 'es'
        ? response.data.message?.question?.prompt_spn || ""
        : response.data.message?.question?.prompt || "";

        const isYesNo =
          response.data.message?.question?.expected_response === "Yes/No";
        const isTransition = response.data.phase !== currentLocation;

        if (isTransition) {
          setShowTransitionAlert(true);
          setCurrentLocation(response.data.phase);
        }

        const assistantMessage = {
          role: "assistant",
          content: promptText,
          phase: response.data.phase,
          item: response.data.item,
          isQuestion: true,
          isTransition: isTransition,
          isYesNo: isYesNo,
        };

        setMessages((prev) => [...prev, assistantMessage]);
        setNextQuestion(response.data);

        // Update completion stats if available
        if (response.data.completion) {
          setCompletionStats({
            completion_percentage: response.data.completion,
          });
        }
      }
    } catch (err) {
      console.error("Error skipping question:", err);
    } finally {
      setIsLoading(false);
      setIsThinking(false);
      setIsSending(false);
    }
  };

  // Modified key press handler to respect editing mode
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (isEditing) {
        handleUpdateMessage();
      } else {
        handleSendMessage();
      }
    }
  };

  const handleYesNoResponse = async (response) => {
    try {
      setIsLoading(true);
      setIsThinking(true);
      setIsSending(true);

      const userMessage = {
        role: "user",
        content: response,
        phase: nextQuestion?.phase || messages[messages.length - 1]?.phase,
        item: nextQuestion?.item || messages[messages.length - 1]?.item,
        isAnswer: true,
      };
      setMessages((prev) => [...prev, userMessage]);

      const apiResponse = await apiClient.post("/chat-questionaire/", {
        message: response,
        phase: userMessage.phase,
        item: userMessage.item,
        id: id,
      });

      if (apiResponse.data) {
        await new Promise((resolve) => setTimeout(resolve, 500));

        // const promptText = apiResponse.data.message?.question?.prompt || "";
  
        // With this
        const promptText = t('speech') === 'es'
        ? apiResponse.data.message?.question?.prompt_spn || ""
        : apiResponse.data.message?.question?.prompt || "";
        
        const isYesNo =
          apiResponse.data.message?.question?.expected_response === "Yes/No";
        const isTransition = apiResponse.data.phase !== currentLocation;

        if (isTransition) {
          setShowTransitionAlert(true);
          setCurrentLocation(apiResponse.data.phase);
        }

        const assistantMessage = {
          role: "assistant",
          content: promptText,
          phase: apiResponse.data.phase,
          item: apiResponse.data.item,
          isQuestion: true,
          isTransition: isTransition,
          isYesNo: isYesNo,
        };

        setMessages((prev) => [...prev, assistantMessage]);
        setNextQuestion(apiResponse.data);

        if (apiResponse.data.completion !== undefined) {
          setCompletionStats({
            completion_percentage: parseFloat(apiResponse.data.completion),
          });
          console.log(
            "Updated completion from Yes/No:",
            parseFloat(apiResponse.data.completion)
          ); // Debug log
        }
      }
    } catch (err) {
      console.error("Error sending yes/no response:", err);
      if (err.response?.status === 401) {
        handleSessionExpired();
      }
    } finally {
      setIsLoading(false);
      setIsThinking(false);
      setIsSending(false);
    }
  };

  // Update handleFinishScope to actually make the API call and redirect
  const handleFinishScope = async () => {
    try {
      const response = await apiClient.post(`/finish-scope/?id=${id}`);
      if (response.data.completion === 100) {
        // Navigate to project details page
        navigate(`/project/${id}`);
      }
    } catch (error) {
      console.error("Error finishing scope:", error);
      if (error.response?.status === 401) {
        handleSessionExpired();
      }
    }
  };

  // Add handler for the Finish Scope button click
  const handleFinishScopeClick = () => {
    setShowConfirmationModal(true);
  };

  // Add cancel handler
  const handleCancelFinish = () => {
    setShowConfirmationModal(false);
  };

  return (
    // Main container with responsive padding for different screen sizes
    <div className="flex flex-col h-screen bg-white pt-[95px] mb-2 md:pt-[120px]">
      {/* Header area with filter dropdown */}
      <header className="flex items-center px-4 py-1 border-b border-gray-200">
        <div className="max-w-2xl mx-auto w-full">
          <div
            className="relative rounded-lg p-2 mb-2"
            style={{
              background: getCompletionColor(
                completionStats?.completion_percentage || 0
              ),
              transition: "background 0.3s ease",
              maxWidth: "600px",
            }}
          >
            <div className="flex justify-between items-center space-x-4">
              {/* Left side: Completion percentage */}
              <div className="text-sm font-medium text-gray-700 min-w-[100px]">
                {completionStats?.completion_percentage?.toFixed(2) || "0.00"}%
              </div>

              {/* Right side: Location filter */}
              <div className="flex items-center space-x-2">
                <select
                  id="location-filter"
                  value={filterLocation}
                  onChange={(e) => setFilterLocation(e.target.value)}
                  className="appearance-none bg-white bg-opacity-80 border border-gray-200 rounded-md 
                          px-2 py-0.5 text-sm font-medium text-gray-700 {/* Reduced px-3 py-1 to px-2 py-0.5 */}
                          focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-transparent
                          hover:bg-opacity-100 transition-all duration-200"
                >
                  {uniqueLocations.map((location) => (
                    <option key={location} value={location}>
                      {location === "all"
                        ? t('speech') === 'es' ? "Secciones Completadas" : "Completed Sections"
                        : location.charAt(0).toUpperCase() + location.slice(1)}
                    </option>
                  ))}
                </select>
                {filterLocation !== "all" && (
                  <button
                    onClick={() => setFilterLocation("all")}
                    className="text-sm text-green-600 hover:text-green-800 font-medium transition-colors"
                  >
                    Clear
                  </button>
                )}
              </div>
            </div>

            {/* Progress bar label - reduced padding */}
            <div className="absolute bottom-0 left-0 right-0 px-2 py-0.5 text-xs text-gray-500">
              {completionStats?.completion_percentage === 100
                ? t('speech') === 'es' ? 'Completado' : 'Complete'
                : t('speech') === 'es' ? 'En Progreso' : 'In Progress'}
            </div>
          </div>
        </div>
      </header>

      {/* Main chat area with filtered messages */}

      {historyLoading ? (
        <div className="flex-1 overflow-y-auto">
          <div className="max-w-2xl mx-auto px-4 py-6">
            <ChatSkeleton />
          </div>
        </div>
      ) : (
        <div className="flex-1 overflow-y-auto">
          <div className="max-w-2xl mx-auto px-4 py-6 space-y-6">
            {/* Map through and render all messages */}
            {filterMessages(messages).map((message, index) => (
              <div
                key={index}
                className={`flex ${
                  message.role === "user" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`flex items-start space-x-4 max-w-[80%] ${
                    message.role === "user"
                      ? "flex-row-reverse space-x-reverse"
                      : ""
                  }`}
                >
                  {/* Avatar section with User Icon */}
                  <div
                    className={`w-8 h-8 rounded-full flex-shrink-0 ${
                      message.role === "user" ? "bg-[#f59e0b]" : ""
                    }`}
                  >
                    {message.role === "assistant" ? (
                      <div className="w-full h-full rounded-full bg-gradient-to-br from-blue-50 to-blue-100 flex items-center justify-center shadow-sm">
                        <AIIcon />
                      </div>
                    ) : (
                      <div className="w-full h-full rounded-full flex items-center justify-center text-white">
                        <svg
                          className="w-5 h-5"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                        </svg>
                      </div>
                    )}
                  </div>

                  {/* Message Content Area */}
                  <div
                    className={`space-y-2 ${
                      message.role === "user" ? "text-right" : "text-left"
                    }`}
                  >
                    {/* Phase and Item Label */}
                    {message.isQuestion && (
                      <div className="text-xs text-gray-500 mb-1 capitalize">
                        {message.phase} - {message.item}
                      </div>
                    )}

                    {/* Message Bubble */}
                    <div
                      className={`prose prose-sm inline-block rounded-2xl px-4 py-2 ${
                        message.role === "user"
                          ? "bg-[#f59e0b] text-white"
                          : "bg-gray-100 text-gray-900"
                      }`}
                    >
                      {message.url ? (
                        <div className="space-y-2">
                          <video
                            controls
                            className="rounded-lg max-w-full"
                            style={{ maxHeight: "300px" }}
                          >
                            <source src={message.url} type="video/webm" />
                            Your browser does not support the video tag.
                          </video>

                          {message.content &&
                            message.content !== "Video Response" && (
                              <div className="flex items-start gap-3">
                                {message.role === "user" && (
                                  <div className="flex-shrink-0 mt-1">
                                    <Video size={20} className="text-white" />
                                  </div>
                                )}
                                <div className="flex-grow text-sm">
                                  <p className="m-0 whitespace-pre-wrap">
                                    {message.content === "N/A" ? (t('speech') === 'es' ? "Saltado" : "Skipped") : message.content}
                                  </p>
                                </div>
                              </div>
                            )}
                        </div>
                      ) : (
                        <div className="flex items-start gap-3">
                          {message.role === "user" && (
                            <div className="flex-shrink-0 mt-1">
                              <Mic size={20} className="text-white" />
                            </div>
                          )}
                          <div
                            className={`flex-grow text-sm ${
                              !message.role === "user" ? "pl-0" : ""
                            }`}
                          >
                            <p className="m-0 whitespace-pre-wrap">
                              {message.content === "N/A" ? (t('speech') === 'es' ? "Saltado" : "Skipped") : message.content}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* Edit Button */}
                    {message.role === "user" && message.isAnswer && (
                      <div className="flex justify-end">
                        <button
                          onClick={() => handleEditMessage(message)}
                          className="p-1 hover:bg-gray-100 rounded hover:bg-gray-200 transition-colors"
                        >
                          <svg
                            className="w-4 h-4 text-gray-600"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {/* Video upload status in the message flow */}
            {isUploadingVideo && !isThinking && (
              <div className="flex justify-end">
                <div className="flex items-start space-x-4 max-w-[80%] flex-row-reverse space-x-reverse">
                  <div className="w-8 h-8 rounded-full flex-shrink-0 bg-[#f59e0b]">
                    <div className="w-full h-full rounded-full flex items-center justify-center text-white">
                      <Video size={20} />
                    </div>
                  </div>
                  <div className="space-y-2 text-right">
                    <div className="prose prose-sm inline-block rounded-2xl px-4 py-2 bg-[#f59e0b] text-white">
                      <div className="flex items-center gap-2">
                        <Loader className="animate-spin" size={16} />
                        <span>Uploading video...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Thinking Indicator */}
            {isThinking && (
              <div className="flex justify-start">
                <div className="flex items-start space-x-4">
                  <div className="w-8 h-8 rounded-full flex-shrink-0">
                    <div className="w-full h-full rounded-full bg-gradient-to-br from-blue-50 to-blue-100 flex items-center justify-center shadow-sm">
                      <ThinkingAIIcon />
                    </div>
                  </div>
                  <div className="bg-gray-100 rounded-2xl px-4 py-2">
                    <ThinkingIndicator />
                  </div>
                </div>
              </div>
            )}

            {/* Scroll anchor for new messages */}
            <div ref={chatEndRef} />
          </div>
        </div>
      )}

      {/* Input Area */}
      <div className="border-t border-gray-200 px-4 py-4">
        <div className="max-w-2xl mx-auto">
          {/* Edit Mode Indicator */}
          {isEditing && (
            <div className="mb-2 px-4 py-2 bg-blue-50 rounded-lg flex items-center justify-between">
              <span className="text-sm text-blue-700">
                Editing message from {editingMessage?.phase} -{" "}
                {editingMessage?.item}
              </span>
              <button
                onClick={handleCancelEdit}
                className="text-blue-700 hover:text-blue-800"
              >
                <X size={16} />
              </button>
            </div>
          )}

          {/* Yes/No Buttons for Yes/No questions */}
          {!isEditing &&
            nextQuestion?.message?.question?.expected_response === "Yes/No" && (
              <div className="flex justify-center space-x-4 mb-4">
                <button
                  onClick={() => handleYesNoResponse("yes")}
                  disabled={isLoading || isThinking || isSending}
                  className={`px-6 py-2 rounded-lg transition-colors text-sm font-medium
                  ${isLoading || isThinking
                    ? "bg-green-200 text-green-400"
                    : "bg-green-500 hover:bg-green-600 text-white"
                  }`}
                >
                  {t('speech') === 'es' ? 'Sí' : 'Yes'}
                </button>
                <button
                  onClick={() => handleYesNoResponse("no")}
                  disabled={isLoading || isThinking || isSending}
                  className={`px-6 py-2 rounded-lg transition-colors text-sm font-medium
                  ${isLoading || isThinking
                    ? "bg-red-200 text-red-400"
                    : "bg-red-500 hover:bg-red-600 text-white"
                  }`}
                >
                  {t('speech') === 'es' ? 'No' : 'No'}
                </button>
              </div>
            )}

          {/* Completion Message */}
          {nextQuestion === null && messages.length > 0 && (
            <div className="flex justify-center mb-6">
              <div className="bg-green-50 text-green-700 px-4 py-3 rounded-lg flex items-center">
                <svg
                  className="w-5 h-5 mr-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                <span>
                  {t('speech') === 'es' 
                    ? '¡Todas las preguntas completadas! Ahora puedes generar tu informe de oferta.'
                    : 'All questions completed! You can now generate your bid report.'}
                </span>
              </div>
            </div>
          )}

          {completionStats?.completion_percentage === 100 && (
            <div className="mb-4">
              <div className="bg-green-50 text-green-700 px-4 py-3 rounded-lg flex items-center justify-between">
                <div className="flex items-center">
                  <svg
                    className="w-8 h-8 mr-3"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <span className="text-base">
                    {t('speech') === 'es'
                      ? '¡El alcance del proyecto está completo! Ahora puedes generar tu informe de oferta.'
                      : 'Project scope is complete! You can now generate your bid report.'}
                  </span>
                </div>
                <button
                  onClick={() => navigate(`/project/${id}`)}
                  className="ml-4 px-4 py-1.5 bg-green-600 text-white rounded-lg text-sm hover:bg-green-700 transition-colors"
                >
                  {t('speech') === 'es' ? 'Ver Proyecto' : 'View Project'}
                </button>
              </div>
            </div>
          )}

          {/* Main Input Area */}
          <div className="flex flex-col space-y-4">
            {/* Input Box with Action Buttons */}
            <div className="relative flex items-end rounded-2xl border border-gray-200 bg-white">
              <textarea
                ref={inputRef}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={handleKeyPress}
                placeholder={
                  isEditing
                    ? t("editMessage")
                    : isThinking
                    ? "Thinking..."
                    : nextQuestion?.message?.question?.expected_response ===
                      "Yes/No"
                    ? "Click Yes or No above, or type your response"
                    : t("chatPlaceholder")
                }
                rows={1}
                disabled={isLoading || isThinking || isSending}
                className="flex-1 max-h-48 p-4 bg-transparent resize-none focus:outline-none disabled:bg-gray-50"
              />

              {/* Action Buttons */}
              <div className="flex items-center px-3 py-2 space-x-2">
                {/* Video Record Button */}
                <button
                  onClick={() => setShowVideoRecorder(true)}
                  className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  disabled={isThinking || isSending}
                  title="Record video"
                >
                  <Video size={20} className="text-gray-600" />
                </button>

                {/* Voice Input */}
                <VoiceTranscription
                  onTranscriptionComplete={handleTranscriptionComplete}
                  isEditing={isEditing}
                  editingMessage={editingMessage}
                  disabled={isThinking || isSending}
                  currentQuestion={nextQuestion?.message?.question?.prompt || ''}
                />

                {/* Send/Update Button */}
                <button
                  onClick={isEditing ? handleUpdateMessage : handleSendMessage}
                  disabled={
                    !input.trim() || isLoading || isThinking || isSending
                  }
                  className={`p-2 rounded-full transition-colors ${
                    input.trim() && !isLoading && !isThinking
                      ? "bg-[#f59e0b] hover:bg-orange-600 text-white"
                      : "bg-gray-100 text-gray-400"
                  }`}
                  title={isEditing ? "Update message" : "Send message"}
                >
                  {isThinking ? (
                    <Loader size={20} className="animate-spin" />
                  ) : (
                    <ArrowUp size={20} />
                  )}
                </button>
              </div>
            </div>

            {/* Skip and Finish Scope Buttons */}
            {!isEditing && (
              <div className="flex justify-center space-x-4">
                <button
                  onClick={handleFinishScopeClick}
                  disabled={isLoading || isThinking || isSending}
                  className={`px-6 py-2 rounded-lg transition-colors text-sm font-medium
                  ${isLoading || isThinking
                    ? "bg-orange-200 text-orange-400"
                    : "bg-[#f59e0b] hover:bg-orange-600 text-white"
                  }`}
                >
                  {t("finish")}
                </button>

                {nextQuestion?.message?.question?.prompt?.toLowerCase().startsWith("let's") ? (
                  <button
                    onClick={() => handleYesNoResponse("yes")}
                    className="px-6 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors text-sm font-medium"
                  >
                    Yes Start
                  </button>
                ) : (
                  <button
                    onClick={handleSkipQuestion}
                    disabled={isLoading || isThinking || isSending}
                    className={`px-6 py-2 rounded-lg transition-colors text-sm font-medium
                    ${isLoading || isThinking
                      ? "bg-gray-100 text-gray-400"
                      : "bg-gray-100 hover:bg-gray-200 text-gray-700"
                    }`}
                  >
                    {t("skip")}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add the confirmation modal */}
      {showConfirmationModal && (
        <ScopeConfirmationModal
          onConfirm={handleFinishScope}
          onCancel={handleCancelFinish}
        />
      )}

      {/* {isUploadingVideo && !isThinking && <VideoUploadStatus />} */}

      {/* Video Recorder Modal */}
      {showVideoRecorder && (
        <VideoRecorder
          onVideoComplete={handleVideoComplete}
          onClose={() => setShowVideoRecorder(false)}
          onUploadStart={() => setIsUploadingVideo(true)}
          currentQuestion={nextQuestion?.message?.question?.prompt || ''}
        />
      )}
    </div>
  );
};

export default ChatInterface;
