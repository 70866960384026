import { useState, useCallback, useEffect } from "react";
import { createClient } from "@deepgram/sdk";
import { LiveTranscriptionEvents } from "@deepgram/sdk";
import { useTranslation } from "react-i18next";

// Initialize the Deepgram client with API key
const deepgram = createClient(process.env.REACT_APP_DEEPGRAM_API_KEY);

export default function useTranspileStream() {
  // State for managing transcription text
  const [finalTranscript, setFinalTranscript] = useState("");
  const [interimTranscript, setInterimTranscript] = useState("");
  
  // State for managing media resources
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [deepgramSocket, setDeepgramSocket] = useState(null);
  
  const { t } = useTranslation();

  const [language, setLanguage] = useState(t('speech'));

  useEffect(()=>{
    setLanguage(t('speech'))
  },[])

  // Function to clear transcription state
  const clearTranscripts = useCallback(() => {
    setFinalTranscript("");
    setInterimTranscript("");
  }, []);

  // Main function to start transcription
  const start = useCallback((stream) => {
    // Validate stream
    if (!stream?.active) {
      console.error("Invalid or inactive media stream");
      return;
    }

    try {
      // Clear any existing transcripts before starting
      clearTranscripts();

      // Initialize Deepgram socket with optimal settings for voice transcription
      const socket = deepgram.listen.live({
        model: "nova-2",  // Using Nova model for better accuracy
        smart_format: true,      // Enables automatic punctuation and formatting
        language: "multi",    // Set to English
        punctuate: true,         // Enable punctuation
        interim_results: true,   // Enable interim results for real-time feedback
        endpointing: true        // Enable automatic speech endpoint detection
      });

      // Store socket reference
      setDeepgramSocket(socket);

      // Initialize MediaRecorder with optimal settings for voice
      const getSupportedAudioMimeType = () => {
        const types = [
          'audio/webm;codecs=opus',
          'audio/webm',
          'audio/mp4',
          'audio/aac'
        ];
        for (const type of types) {
          if (MediaRecorder.isTypeSupported(type)) {
            return type;
          }
        }
        return '';
      };
      
      const recorder = new MediaRecorder(stream, {
        mimeType: getSupportedAudioMimeType()
      });
      
      // Store recorder reference
      setMediaRecorder(recorder);

      // Set up Deepgram event listeners
      socket.addListener(LiveTranscriptionEvents.Open, () => {
        // Set up data handling when socket is open
        recorder.addEventListener("dataavailable", (event) => {
          // Only send data if we have valid data and an open socket
          if (event.data.size > 0 && socket.readyState === socket.OPEN) {
            socket.send(event.data);
          }
        });

        // Start recording with 250ms chunks for smooth real-time transcription
        recorder.start(250);
      });

      // Handle incoming transcripts
      socket.addListener(LiveTranscriptionEvents.Transcript, (data) => {
        const transcript = data.channel.alternatives[0].transcript;
        
        if (data.is_final) {
          // For final transcripts, append to existing text while avoiding duplicates
          setFinalTranscript((prevFinal) => {
            // Avoid duplicate text by checking if the new transcript is already at the end
            if (prevFinal.endsWith(transcript)) return prevFinal;
            // Add space between transcripts if there's existing text
            return prevFinal ? prevFinal + " " + transcript : transcript;
          });
          // Clear interim transcript when we get final text
          setInterimTranscript("");
        } else {
          // Update interim transcript for real-time feedback
          setInterimTranscript(transcript);
        }
      });

      // Handle errors
      socket.addListener(LiveTranscriptionEvents.Error, (error) => {
        console.error("Deepgram transcription error:", error);
      });

      // Handle connection closure
      socket.addListener(LiveTranscriptionEvents.Close, () => {
        // Stop recording if still active
        if (recorder.state !== "inactive") {
          recorder.stop();
        }
        // Clear socket reference
        setDeepgramSocket(null);
      });

    } catch (error) {
      console.error("Error initializing transcription:", error);
      // Clean up any partially initialized resources
      if (mediaRecorder) {
        mediaRecorder.stop();
        setMediaRecorder(null);
      }
      if (deepgramSocket) {
        deepgramSocket.requestClose();
        setDeepgramSocket(null);
      }
    }
  }, [clearTranscripts]);

  // Function to stop transcription
  const stop = useCallback(() => {
    // Stop media recorder if active
    if (mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();
      setMediaRecorder(null);
    }

    // Close Deepgram socket if open
    if (deepgramSocket && deepgramSocket.readyState === deepgramSocket.OPEN) {
      deepgramSocket.requestClose();
      setDeepgramSocket(null);
    }
  }, [mediaRecorder, deepgramSocket]);

  // Return the hook interface
  return {
    startTranscription: start,
    stopTranscription: stop,
    interimTranscript,
    finalTranscript,
    setFinalTranscript,
  };
}