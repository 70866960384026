import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  FileText,
  Palette,
  Award,
  ArrowRight,
  Building2,
  Move,
  Bed,
  Bath,
  Building,
  Clock,
  Hammer
} from "lucide-react";

import GooglePlacesAutocomplete from "./googlePlacesAutocomplete";
import ProjectConfirmationDialog from "./projectConfirmationDialog";

import { Alert, AlertDescription } from "../ui/alert";
import AuthFormWrapper from "../auth/authFormWrapper";
import StyledButton from "../ui/styledButton";
import StyledInput from "../ui/styledInput";
import StyledSelect from "../ui/styledSelect";
import useHandleSessionExpired from "../../utils/useHandleSessionExpired";
import useShowToast from "../../utils/useShowToast";
import { useTranslation } from "react-i18next";

const CreateProjectScreen = () => {
  const showToast = useShowToast();
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [colorTemplate, setColorTemplate] = useState("");
  const [quality, setQuality] = useState("");
  const [address, setAddress] = useState("");
  const [squareFootage, setSquareFootage] = useState("");
  const [totalBedrooms, setTotalBedrooms] = useState("");
  const [totalBathrooms, setTotalBathrooms] = useState("");
  const [stories, setStories] = useState("");
  const [yearBuilt, setYearBuilt] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isManualEntry, setIsManualEntry] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [zillowProjectData, setZillowProjectData] = useState(null);
  const [renovationType, setRenovationType] = useState("");

  const [formData, setFormData] = useState(null);


  const navigate = useNavigate();
  const handleSessionExpired = useHandleSessionExpired();

  const colorTemplateOptions = [
    { value: "Grays", label: t("grayTemplate") },
    { value: "Beige", label: t("beigeTemplate") },
  ];

  const qualityOptions = [
    { value: "A", label: t("qualityA") },
    { value: "B", label: t("qualityB") },
  ];

  const propertyTypeOptions = [
    { value: "residential", label: t("residential") },
    { value: "commercial", label: t("commercial") },
  ];

  const renovationTypeOptions = [
    { value: "F", label: t("fullRenovation") },
    { value: "C", label: t("cosmeticRenovation") },
  ];


  const validateForm = () => {
    const newErrors = {};
    if (!colorTemplate) newErrors.colorTemplate = t("colorTemplateRequired");
    if (!quality) newErrors.quality = t("qualityRequired");
    // if (!propertyType) newErrors.propertyType = t("propertyTypeRequired");
    if (!renovationType) newErrors.renovationType = t("renovationTypeRequired");
    if (!isManualEntry && !address) newErrors.address = t("addressRequired");
    if (isManualEntry) {
      if (!squareFootage) newErrors.squareFootage = t("squareFootageRequired");
      if (!totalBedrooms) newErrors.totalBedrooms = t("totalBedroomsRequired");
      if (!totalBathrooms) newErrors.totalBathrooms = t("totalBathroomsRequired");
      if (!stories) newErrors.stories = t("storiesRequired");
      if (!yearBuilt) newErrors.yearBuilt = t("yearBuiltRequired");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateProject = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true);
      try {
        if (!isManualEntry) {
          // First, just get property details from Zillow
          const zillowResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/zillow-details/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                accept: "application/json",
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
              body: JSON.stringify({
                address: address
              }),
            }
          );

          const zillowData = await zillowResponse.json();

          if (zillowResponse.ok && !zillowData.exception) {
            // Store the form data and Zillow data separately
            setFormData({
              name,
              color_template: colorTemplate,
              quality,
              address,
              property_type: propertyType,
            });
            
            // Store the Zillow property details
            setZillowProjectData({
              square_footage: zillowData.square_footage,
              total_bedrooms: zillowData.total_bedrooms,
              total_bathrooms: zillowData.total_bathrooms,
              stories: zillowData.stories,
              year_built: zillowData.year_built,
              property_image: zillowData.property_image,
              address:zillowData.address,
            });
            
            setShowConfirmation(true);
            setIsLoading(false);
            return;
          }

          // If Zillow failed, switch to manual entry
          setIsManualEntry(true);
          setErrors({
            form: "We couldn't find the property details. Please enter them manually.",
          });
          // Pre-fill any fields we might have from Zillow
          if (zillowData.square_footage) setSquareFootage(zillowData.square_footage);
          if (zillowData.total_bedrooms) setTotalBedrooms(zillowData.total_bedrooms);
          if (zillowData.total_bathrooms) setTotalBathrooms(zillowData.total_bathrooms);
          if (zillowData.stories) setStories(zillowData.stories);
          if (zillowData.year_built) setYearBuilt(zillowData.year_built);
          setIsLoading(false);
          return;
        }

        // For manual entry
        await handleManualSubmission();

      } catch (error) {
        setErrors({ form: "An error occurred. Please try again." });
        setIsLoading(false);
      }
    }
  };

  const handleManualSubmission = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/create-project/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify(
            Object.fromEntries(
              Object.entries({
                name,
                color_template: colorTemplate,
                quality,
                address: isManualEntry ? name : address,
                square_footage: squareFootage,
                total_bedrooms: totalBedrooms,
                total_bathrooms: totalBathrooms,
                stories,
                year_built: yearBuilt,
                property_type: "fixed",
              }).filter(([_, value]) => value !== "" && value !== undefined)
            )
          ),
        }
      );

      const data = await response.json();

      if (response.ok) {
        showToast("Project created successfully", "success");
        navigate(`/chat/${data?.project_id}`, { state: { projectData: data } });
      } else if (response.status === 401) {
        handleSessionExpired();
      } else {
        setErrors({
          form: data.detail || "Failed to create project. Please try again.",
        });
      }
    } catch (error) {
      setErrors({ form: "An error occurred. Please try again." });
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmProject = async (updatedProjectData) => {
    if (!updatedProjectData) return;
    
    try {
      setIsLoading(true);
      
      // Combine all the necessary data
      const submissionData = {
        name: name || updatedProjectData.address,
        color_template: colorTemplate,
        quality,
        property_type: "Fixed",
        renovation_type: renovationType,
        // Use updated project data for property details
        address: updatedProjectData.address,
        square_footage: updatedProjectData.square_footage,
        total_bedrooms: updatedProjectData.total_bedrooms,
        total_bathrooms: updatedProjectData.total_bathrooms,
        stories: updatedProjectData.stories,
        year_built: updatedProjectData.year_built,
        property_image: updatedProjectData.property_image,
      };

      // Validate required fields
      const requiredFields = [
        'name',
        'color_template',
        'quality',
        'property_type',
        'renovation_type',
        'address',
        'square_footage',
        'total_bedrooms',
        'total_bathrooms',
        'stories',
        'year_built'
      ];

      const missingFields = requiredFields.filter(field => !submissionData[field]);

      if (missingFields.length > 0) {
        setErrors({
          form: `Missing required fields: ${missingFields.join(', ')}`
        });
        setIsLoading(false);
        return;
      }

      // Clean the data by removing any empty strings, null, or undefined values
      const cleanedData = Object.entries(submissionData)
        .reduce((acc, [key, value]) => {
          if (value !== "" && value !== null && value !== undefined) {
            // Convert numeric strings to numbers where appropriate
            const numericFields = ['square_footage', 'total_bedrooms', 'total_bathrooms', 'stories', 'year_built'];
            acc[key] = numericFields.includes(key) ? Number(value) : value;
          }
          return acc;
        }, {});

      // Send the request
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/create-project/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify(cleanedData),
        }
      );

      const data = await response.json();

      if (response.ok) {
        showToast("Project created successfully", "success");
        navigate(`/chat/${data?.project_id}`, { 
          state: { 
            projectData: data,
            isNewProject: true
          } 
        });
      } else if (response.status === 401) {
        handleSessionExpired();
      } else {
        let errorMessage = "Failed to create project. Please try again.";
        if (data.detail) {
          errorMessage = typeof data.detail === 'object' 
            ? Object.values(data.detail).join(', ')
            : data.detail;
        }
        setErrors({ form: errorMessage });
        
        // If there are field-specific errors, set them
        if (typeof data.detail === 'object') {
          const fieldErrors = {};
          Object.entries(data.detail).forEach(([field, error]) => {
            fieldErrors[field] = Array.isArray(error) ? error[0] : error;
          });
          setErrors(prev => ({ ...prev, ...fieldErrors }));
        }
      }
    } catch (error) {
      console.error('Project creation error:', error);
      setErrors({ 
        form: "An unexpected error occurred while creating the project. Please try again." 
      });
    } finally {
      setIsLoading(false);
      setShowConfirmation(false);
    }
  };

  return (
    <AuthFormWrapper
      title={t("createNewProject")}
      subtitle={t("startYourNewProject")}
      className="!pt-36"
    >

      <ProjectConfirmationDialog
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onConfirm={handleConfirmProject}
        projectData={zillowProjectData}
        isLoading={isLoading}
      />

      <form className="mt-8 space-y-6" onSubmit={handleCreateProject}>
        {errors.form && (
          <Alert variant="destructive">
            <AlertDescription>{errors.form}</AlertDescription>
          </Alert>
        )}
        <div>
          {/* <StyledInput
            icon={Building2}
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Project Name"
            error={errors.name}
            required
          /> */}
          <StyledSelect
            icon={Palette}
            value={colorTemplate}
            onChange={(e) => setColorTemplate(e.target.value)}
            options={colorTemplateOptions}
            placeholder={t("selectColorTemplate")}
            error={errors.colorTemplate}
            required
          />
          <StyledSelect
            icon={Award}
            value={quality}
            onChange={(e) => setQuality(e.target.value)}
            options={qualityOptions}
            placeholder={t("selectQuality")}
            error={errors.quality}
            required
          />
          {/* <StyledSelect
            icon={Building2}
            value={propertyType}
            onChange={(e) => setPropertyType(e.target.value)}
            options={propertyTypeOptions}
            placeholder={t("selectPropertyType")}
            error={errors.propertyType}
            required
          /> */}
          <StyledSelect
            icon={Hammer}
            value={renovationType}
            onChange={(e) => setRenovationType(e.target.value)}
            options={renovationTypeOptions}
            placeholder={t("selectRenovationType")}
            error={errors.renovationType}
            required
          />
        </div>
        <div>
          <label className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={isManualEntry}
              onChange={() => setIsManualEntry(!isManualEntry)}
              className="mr-2"
            />
            {t("enterDetailsManually")}
          </label>
        </div>

        {!isManualEntry ? (
          <GooglePlacesAutocomplete
            value={address}
            onChange={(e) =>{ 
              setAddress(e); 
              setName(e);
            }}
            placeholder={t("enterAddress")}
            error={errors.address}
            required
          />
        ) : (
          <>
            <StyledInput
              icon={FileText}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t("projectName")}
              error={errors.name}
              required
            />
            <StyledInput
              icon={Move}
              type="text"
              value={squareFootage}
              onChange={(e) => setSquareFootage(e.target.value)}
              placeholder={t("squareFootage")}
              error={errors.squareFootage}
              required
            />
            <StyledInput
              icon={Bed}
              type="text"
              value={totalBedrooms}
              onChange={(e) => setTotalBedrooms(e.target.value)}
              placeholder={t("totalBedrooms")}
              error={errors.totalBedrooms}
              required
            />
            <StyledInput
              icon={Bath}
              type="text"
              value={totalBathrooms}
              onChange={(e) => setTotalBathrooms(e.target.value)}
              placeholder={t("totalBathrooms")}
              error={errors.totalBathrooms}
              required
            />
            <StyledInput
              icon={Building}
              type="text"
              value={stories}
              onChange={(e) => setStories(e.target.value)}
              placeholder={t("numberOfStories")}
              error={errors.stories}
              required
            />
            <StyledInput
              icon={Clock}
              type="text"
              value={yearBuilt}
              onChange={(e) => setYearBuilt(e.target.value)}
              placeholder={t("yearBuilt")}
              error={errors.yearBuilt}
              required
            />
          </>
        )}
        <div>
          <StyledButton type="submit" isLoading={isLoading}>
            {t("createProject")}
            <ArrowRight className="ml-2 h-4 w-4" />
          </StyledButton>
        </div>
      </form>
    </AuthFormWrapper>
  );
};

export default CreateProjectScreen;
