import React, { useState, useRef, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
// Remove manual html2canvas import since pdf.html() uses it internally
// import html2canvas from 'html2canvas';

import useHandleSessionExpired from "../../../utils/useHandleSessionExpired";
import {
  useGetProjectByIdQuery,
  useGetScopeDataQuery
} from "../../../api/apiSlice";
import { useGetDocumentQuery, useStorePdfMutation, useShareDocumentMutation } from "../../../api/apiSlice";
import profileApi from '../../../api/profileApi';

import { CheckCircle } from 'lucide-react';
import ReportLoader from './report-loader';

const CheckmarkAnimation = () => (
  <div className="flex items-center justify-center p-4">
    <CheckCircle 
      size={48} 
      className="text-green-500 animate-[bounce_1s_ease-in-out]" 
    />
  </div>
);

const ShareModal = ({ isOpen, onClose, documentId }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);

  const [shareDocument, { isLoading }] = useShareDocumentMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!documentId) {
      return;
    }
    
    try {
      await shareDocument({
        documentId,
        email,
        message
      }).unwrap();

      setShowSuccess(true);

      setTimeout(() => {
        setEmail('');
        setMessage('');
        setShowSuccess(false);
        onClose();
      }, 3000);

    } catch (error) {
      console.error('Error sharing document:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96 relative">
        <button 
          onClick={onClose} 
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          type="button"
          aria-label="Close"
        >
          ✕
        </button>
        <h2 className="text-xl font-bold mb-4">Share Document</h2>
        
        {showSuccess ? (
          <div className="py-8">
            <CheckmarkAnimation />
            <p className="text-center text-green-600 mt-4 font-medium">
              Document shared successfully!
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label 
                htmlFor="email" 
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Recipient Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter email address"
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
            </div>
            
            <div>
              <label 
                htmlFor="message" 
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Message (Optional)
              </label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Add a message..."
                className="w-full p-2 border border-gray-300 rounded"
                rows="3"
              />
            </div>

            <div className="flex justify-end gap-2">
              <button 
                type="button" 
                onClick={onClose}
                disabled={isLoading}
                className="px-4 py-2 border border-gray-300 rounded hover:bg-gray-50"
              >
                Cancel
              </button>
              <button 
                type="submit"
                disabled={isLoading}
                className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600 disabled:opacity-50"
              >
                {isLoading ? 'Sending...' : 'Send'}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

const ScopeOfWorkForm = () => {
  const { id } = useParams();
  const handleSessionExpired = useHandleSessionExpired();
  const [isEditing, setIsEditing] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const formRef = useRef(null);

  const [qrCodeUrl, setQrCodeUrl] = useState(null);
  const { data: documentData } = useGetDocumentQuery(id);
  const [storePdf] = useStorePdfMutation();
  const [companyData, setCompanyData] = useState(null);

  // Update QR code URL with proper formatting
  useEffect(() => {
    if (documentData?.qr_code_url) {
      const qrUrl = documentData.qr_code_url.startsWith('http') 
        ? documentData.qr_code_url 
        : `https://${documentData.qr_code_url}`;
      setQrCodeUrl(qrUrl);
    }
  }, [documentData]);

  // Fetch data with error handling
  const { 
    data: projectData, 
    isLoading: projectLoading,
    error: projectError 
  } = useGetProjectByIdQuery(id, {
    onError: (error) => {
      if (error?.status === 401) {
        handleSessionExpired();
      }
    }
  });

  const { 
    data: scopeData, 
    isLoading: scopeLoading,
    error: scopeError 
  } = useGetScopeDataQuery(id, {
    onError: (error) => {
      if (error?.status === 401) {
        handleSessionExpired();
      }
    }
  });

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const data = await profileApi.getCompanyProfile();
        setCompanyData(data);
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    fetchCompanyData();
  }, []);



  // Form data state
  const [formData, setFormData] = useState({
    projectAddress: '',
    colorTemplate: '',
    quality: '',
    propertyType: '',
    squareFootage: '',
    bedrooms: '',
    bathrooms: '',
    totalOtherRooms: '',
    reportDate: new Date().toLocaleDateString('en-US'),
    companyName: 'Company',
    contactName: 'Tony Diaz',
    address: '567 My Address, Anycity CA 910000',
    phone: '555-1212',
    email: '@Email'
  });


  useEffect(() => {
    if (companyData) {
      setFormData(prev => ({
        ...prev,
        companyName: companyData.name || 'Company',
        address: companyData.address || '567 My Address, Anycity CA 910000',
        phone: companyData.phone || '555-1212',
        email: companyData.email || '@Email'
      }));
    }
  }, [companyData]);

  // Table data
  const [tableData, setTableData] = useState([]);

  // Update form data when project data changes
  useEffect(() => {
    if (projectData) {
      setFormData(prev => ({
        ...prev,
        projectAddress: projectData.address ?? '',
        colorTemplate: projectData.color_template ?? '',
        quality: projectData.quality ?? '',
        propertyType: projectData.property_type ?? '',
        squareFootage: projectData.square_footage ?? '',
        bedrooms: projectData.total_bedrooms ?? '',
        bathrooms: projectData.total_bathrooms ?? '',
        totalOtherRooms: projectData.stories ?? ''
      }));
    }
  }, [projectData]);

  // Update table data when scope data changes
  useEffect(() => {
    if (scopeData?.scope_items) {
      const validatedItems = scopeData.scope_items.map(item => ({
        ...item,
        laborHours: parseFloat(item.laborHours || 0).toString(),
        cost: parseFloat(item.cost || 0).toString()
      }));
      setTableData(validatedItems);
    }
  }, [scopeData]);

  // Handle saving changes
  const handleSave = async () => {
    try {
      setIsEditing(false);
      // You can call your update API here if needed
    } catch (error) {
      if (error?.status === 401) {
        handleSessionExpired();
      } else {
        console.error('Error saving data:', error);
        alert('Failed to save changes. Please try again.');
      }
    }
  };

  // Calculate totals
  const totals = useMemo(() => {
    return tableData.reduce((acc, row) => ({
      laborHours: acc.laborHours + (parseFloat(row.laborHours) || 0),
      cost: acc.cost + (parseFloat(row.cost) || 0)
    }), { laborHours: 0, cost: 0 });
  }, [tableData]);

  // Handle table data changes with validation
  const handleTableDataChange = (index, field, value) => {
    const newData = [...tableData];
    
    if (field === 'laborHours' || field === 'cost') {
      // Allow only numbers and one decimal point
      const numericValue = value.replace(/[^\d.]/g, '');
      const parts = numericValue.split('.');
      const formattedValue = parts.length > 1 
        ? `${parts[0]}.${parts[1].slice(0, 2)}` 
        : numericValue;
      
      newData[index] = { ...newData[index], [field]: formattedValue };
    } else {
      newData[index] = { ...newData[index], [field]: value };
    }
    
    setTableData(newData);
  };

  // Generate & Export PDF using jsPDF's built-in html() method
  const handleExportPDF = async () => {
    if (!formRef.current) return;
    
    const actionButtons = document.querySelector('.action-buttons');
    if (actionButtons) {
      actionButtons.style.display = 'none';
    }

    try {
      // Create a new jsPDF instance
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });

      // Render the formRef content into the PDF
      // jsPDF will handle paging automatically
      await pdf.html(formRef.current, {
        x: 12,
        y: 12,
        html2canvas: {
          useCORS: true,
          scale: 0.17, // You can adjust scale if text looks too big/small
        },
      });

      // Convert PDF to base64
      const pdfContent = pdf.output('arraybuffer');
      const base64Pdf = btoa(
        new Uint8Array(pdfContent).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      );

      // Store PDF and get updated QR code (if needed)
      const response = await storePdf({ 
        projectId: id,
        pdf_content: base64Pdf 
      }).unwrap();

      if (response.qr_code_url) {
        const newQrUrl = response.qr_code_url.startsWith('http') 
          ? response.qr_code_url 
          : `https://${response.qr_code_url}`;
        setQrCodeUrl(newQrUrl);
      }

      // Download the PDF locally
      pdf.save(`scope-of-work-${id}.pdf`);
      
    } catch (error) {
      console.error('Error generating PDF:', error);
      alert('Failed to generate PDF. Please try again.');
    } finally {
      if (actionButtons) {
        actionButtons.style.display = 'flex';
      }
    }
  };

  if (projectLoading || scopeLoading) {
    return <ReportLoader />;
  }

  if (projectError || scopeError) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="text-red-600">
          Error: {projectError?.message || scopeError?.message || 'Failed to load data'}
        </div>
      </div>
    );
  }

  // QR Code display section
  const QRCodeDisplay = ({ qrCodeUrl }) => {
    const [imageUrl, setImageUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      if (!qrCodeUrl) {
        setIsLoading(false);
        return;
      }

      const loadImage = async () => {
        try {
          setIsLoading(true);
          setError(null);

          const fullUrl = qrCodeUrl.startsWith('http') 
            ? qrCodeUrl 
            : `https://${qrCodeUrl}`;

          const response = await fetch(fullUrl);
          if (!response.ok) {
            throw new Error(`Failed to load QR code: ${response.status}`);
          }

          setImageUrl(fullUrl);
          setIsLoading(false);
        } catch (err) {
          console.error('Error loading QR code:', err);
          setError(err.message);
          setIsLoading(false);
        }
      };

      loadImage();
    }, [qrCodeUrl]);

    if (isLoading) {
      return (
        <div className="w-40 h-40 bg-gray-100 flex items-center justify-center rounded-lg">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="w-40 h-40 bg-gray-100 flex flex-col items-center justify-center p-2 text-center rounded-lg">
          <span className="text-red-500 text-sm">Unable to load QR code</span>
          <span className="text-gray-500 text-xs mt-1">Please try refreshing</span>
        </div>
      );
    }

    if (!qrCodeUrl) {
      return (
        <div className="w-40 h-40 bg-gray-100 flex items-center justify-center rounded-lg">
          <span className="text-gray-500 text-sm text-center">QR Code will appear here</span>
        </div>
      );
    }

    return (
      <div className="relative w-40 h-40 qr-code-container bg-white rounded-lg shadow-sm">
        <img
          src={imageUrl}
          alt="QR Code"
          className="w-full h-full object-contain p-2"
          onError={() => setError('Failed to load image')}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col h-screen bg-white pt-[95px] mb-2 md:pt-[120px]">
      <div className="max-w-6xl mx-auto mb-4 flex justify-end gap-4 px-4 action-buttons">
        <button
          onClick={() => (isEditing ? handleSave() : setIsEditing(true))}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          type="button"
        >
          {isEditing ? 'Save' : 'Edit'}
        </button>
        <button 
          onClick={handleExportPDF}
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          type="button"
        >
          Generate & Export as PDF
        </button>
        <button 
          onClick={() => setShowShareModal(true)}
          className="px-4 py-2 bg-purple-500 text-white rounded hover:bg-purple-600"
          type="button"
        >
          Share
        </button>
      </div>

      <div ref={formRef} className="max-w-6xl mx-auto bg-white p-8 shadow-lg pdf-content">
        <div className="text-center mb-6">
          <h1 className="text-xl font-bold">Scope of Work Form</h1>
        </div>

        <div className="grid grid-cols-12 gap-8 pdf-header">
          <div className="col-span-5">
            <div className="space-y-2">
              {[
                ['Project Address:', 'projectAddress'],
                ['Color Template:', 'colorTemplate'],
                ['Quality:', 'quality'],
                ['Property Type:', 'propertyType'],
                ['Square Footage:', 'squareFootage'],
                ['Bedrooms:', 'bedrooms'],
                ['Bathrooms:', 'bathrooms'],
                ['Total Other Rooms:', 'totalOtherRooms'],
                ['Report Date:', 'reportDate']
              ].map(([label, field]) => (
                <div key={field} className="flex items-center">
                  <label className="w-36 text-right pr-2 text-gray-600">{label}</label>
                  <div className="flex-1 border-b border-gray-300">
                    {isEditing ? (
                      <input
                        type="text"
                        value={formData[field]}
                        onChange={(e) =>
                          setFormData((prev) => ({ ...prev, [field]: e.target.value }))
                        }
                        className="w-full py-1 px-2"
                      />
                    ) : (
                      <span className="block py-1 px-2">{formData[field]}</span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-span-3 flex flex-col items-center justify-start">
            <QRCodeDisplay qrCodeUrl={qrCodeUrl} />
          </div>

          <div className="col-span-4 text-right">
            {/* Replace the "Logo" text with actual logo */}
            <div className="mb-4">
              {companyData?.logo ? (
                <img 
                  src={companyData.logo}
                  alt="Company Logo"
                  className="max-h-24 ml-auto" // Right-aligned
                  onError={(e) => {
                    console.error('Error loading company logo');
                    e.target.style.display = 'none';
                  }}
                />
              ) : (
                <div className="h-24 bg-gray-100 flex items-center justify-center">
                  <span className="text-gray-400">No Logo</span>
                </div>
              )}
            </div>
            <div className="space-y-1">
              {isEditing ? (
                <>
                  <input
                    type="text"
                    value={formData.companyName}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, companyName: e.target.value }))
                    }
                    className="w-full text-right border-b"
                    placeholder="Company Name"
                  />
                  <input
                    type="text"
                    value={formData.contactName}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, contactName: e.target.value }))
                    }
                    className="w-full text-right border-b"
                    placeholder="Contact Name"
                  />
                  <input
                    type="text"
                    value={formData.address}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, address: e.target.value }))
                    }
                    className="w-full text-right border-b"
                    placeholder="Address"
                  />
                  <input
                    type="text"
                    value={formData.phone}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, phone: e.target.value }))
                    }
                    className="w-full text-right border-b"
                    placeholder="Phone"
                  />
                  <input
                    type="text"
                    value={formData.email}
                    onChange={(e) =>
                      setFormData((prev) => ({ ...prev, email: e.target.value }))
                    }
                    className="w-full text-right border-b"
                    placeholder="Email"
                  />
                </>
              ) : (
                <>
                  <div className="font-bold">{formData.companyName}</div>
                  <div>{formData.contactName}</div>
                  <div>{formData.address}</div>
                  <div>{formData.phone}</div>
                  <div>{formData.email}</div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mt-8 overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border border-gray-300 bg-gray-50 px-4 py-2 text-left">Section</th>
                <th className="border border-gray-300 bg-gray-50 px-4 py-2 text-left">Sub-Section</th>
                <th className="border border-gray-300 bg-gray-50 px-4 py-2 text-left">File</th>
                <th className="border border-gray-300 bg-gray-50 px-4 py-2 text-left">Scope of Work</th>
                <th className="border border-gray-300 bg-gray-50 px-4 py-2 text-left">Update/Delete</th>
                <th className="border border-gray-300 bg-gray-50 px-4 py-2 text-right w-32">Labor Hours</th>
                <th className="border border-gray-300 bg-gray-50 px-4 py-2 text-right w-32">Cost</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={row.id}>
                  <td className="border border-gray-300 px-4 py-2">
                    {isEditing ? (
                      <input
                        type="text"
                        value={row.section}
                        onChange={(e) => handleTableDataChange(index, 'section', e.target.value)}
                        className="w-full"
                      />
                    ) : (
                      row.section
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {isEditing ? (
                      <input
                        type="text"
                        value={row.subSection}
                        onChange={(e) => handleTableDataChange(index, 'subSection', e.target.value)}
                        className="w-full"
                      />
                    ) : (
                      row.subSection
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {isEditing ? (
                      <input
                        type="text"
                        value={row.file}
                        onChange={(e) => handleTableDataChange(index, 'file', e.target.value)}
                        className="w-full"
                      />
                    ) : (
                      row.file
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {isEditing ? (
                      <input
                        type="text"
                        value={row.scopeOfWork}
                        onChange={(e) => handleTableDataChange(index, 'scopeOfWork', e.target.value)}
                        className="w-full"
                      />
                    ) : (
                      row.scopeOfWork
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    {isEditing ? (
                      <input
                        type="text"
                        value={row.updateDelete}
                        onChange={(e) => handleTableDataChange(index, 'updateDelete', e.target.value)}
                        className="w-full"
                      />
                    ) : (
                      row.updateDelete
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-right">
                    {isEditing ? (
                      <input
                        type="text"
                        value={row.laborHours}
                        onChange={(e) => handleTableDataChange(index, 'laborHours', e.target.value)}
                        className="w-full text-right"
                      />
                    ) : (
                      row.laborHours
                    )}
                  </td>
                  <td className="border border-gray-300 px-4 py-2 text-right">
                    {isEditing ? (
                      <input
                        type="text"
                        value={row.cost}
                        onChange={(e) => handleTableDataChange(index, 'cost', e.target.value)}
                        className="w-full text-right"
                      />
                    ) : (
                      `${parseFloat(row.cost || 0).toFixed(2)}`
                    )}
                  </td>
                </tr>
              ))}
              <tr className="bg-gray-50 font-bold">
                <td colSpan="5" className="border border-gray-300 px-4 py-2 text-right">
                  Totals:
                </td>
                <td className="border border-gray-300 px-4 py-2 text-right">
                  {totals.laborHours.toFixed(2)}
                </td>
                <td className="border border-gray-300 px-4 py-2 text-right">
                  ${totals.cost.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <ShareModal 
        isOpen={showShareModal} 
        onClose={() => setShowShareModal(false)} 
        documentId={documentData?.document_id}
      />
    </div>
  );
};

export default ScopeOfWorkForm;
