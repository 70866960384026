import React from 'react';

const ChatSkeleton = () => {
  // Create an array of 3 items for skeleton messages
  const skeletonItems = Array(3).fill(null);

  return (
    <div className="animate-pulse space-y-6">
      {/* Header Skeleton */}
      <div className="w-full max-w-2xl mx-auto">
        <div className="h-12 bg-gray-200 rounded-lg w-full"></div>
      </div>

      {/* Messages Skeleton */}
      {skeletonItems.map((_, index) => (
        <div key={index} className={`flex ${index % 2 === 0 ? 'justify-start' : 'justify-end'}`}>
          <div className={`flex items-start space-x-4 max-w-[80%] ${
            index % 2 !== 0 ? 'flex-row-reverse space-x-reverse' : ''
          }`}>
            {/* Avatar */}
            <div className="w-8 h-8 bg-gray-200 rounded-full flex-shrink-0"></div>
            
            {/* Message Content */}
            <div className="space-y-2">
              {/* Phase Label */}
              <div className="h-4 bg-gray-200 rounded w-24"></div>
              
              {/* Message Bubble */}
              <div className="h-16 bg-gray-200 rounded-2xl w-64"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ChatSkeleton;