import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Building2, Mail, Phone, MapPin, User, Save, X, Loader } from "lucide-react";
import { profileApi } from "../../api/profileApi";
import { removeToken } from "../../utils/auth";

// Import custom components
// Import custom components
import { Card, CardHeader, CardTitle, CardContent } from '../ui/card';

import StyledButton from "../ui/styledButton";
import StyledInput from "../ui/styledInput";
import CompanyLogoUpload from "./CompanyLogoUpload";


const EditableInfoItem = ({ icon: Icon, label, value, field, isEditing, data, setData, error }) => (
  <div className="flex flex-col space-y-2 mb-4">
    <div className="flex items-center space-x-2 text-sm font-medium text-gray-700">
      <Icon className="text-gray-500 w-4 h-4" />
      <span>{label}</span>
    </div>
    {isEditing ? (
      <StyledInput
        icon={Icon}
        value={data?.[field] || ''}
        onChange={(e) => setData({ ...data, [field]: e.target.value })}
        placeholder={`Enter ${label.toLowerCase()}`}
        error={error}
      />
    ) : (
      <p className="text-base text-gray-900 py-2">{value || 'Not provided'}</p>
    )}
  </div>
);

const MyAccount = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({
    username: '',
    email: '',
    phone: '',
    address: ''
  });
  const [companyDetails, setCompanyDetails] = useState(null);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [isEditingCompany, setIsEditingCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editedUserData, setEditedUserData] = useState({
    username: '',
    email: '',
    phone: '',
    address: ''
  });
  const [editedCompanyData, setEditedCompanyData] = useState(null);
  const [confirmSignOut, setConfirmSignOut] = useState(false);
  const [logoError, setLogoError] = useState(null);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const [userProfile, companyProfile] = await Promise.all([
        profileApi.getUserProfile(),
        profileApi.getCompanyProfile().catch(() => null)
      ]);
      
      setUserDetails(userProfile || {
        username: '',
        email: '',
        phone: '',
        address: ''
      });
      setEditedUserData(userProfile || {
        username: '',
        email: '',
        phone: '',
        address: ''
      });
      
      if (companyProfile) {
        setCompanyDetails(companyProfile);
        setEditedCompanyData(companyProfile);
      }
    } catch (error) {
      console.error('Error fetching profile:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleUserUpdate = async () => {
    try {
      const updatedUser = await profileApi.updateUserProfile(editedUserData);
      setUserDetails(updatedUser);
      setIsEditingUser(false);
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };

  const handleCompanyUpdate = async () => {
    try {
      const formData = new FormData();
      
      // Debug logging
      console.log('editedCompanyData before FormData creation:', editedCompanyData);
      
      // Append all non-file fields
      Object.keys(editedCompanyData).forEach(key => {
        if (key !== 'logo' && editedCompanyData[key] !== null && editedCompanyData[key] !== undefined) {
          console.log(`Appending ${key}:`, editedCompanyData[key]);
          formData.append(key, editedCompanyData[key]);
        }
      });
      
      // Append logo if it exists and is a File object
      if (editedCompanyData.logo instanceof File) {
        console.log('Appending logo file:', editedCompanyData.logo);
        formData.append('logo', editedCompanyData.logo);
      }
      
      // Debug: Log all FormData entries
      console.log('Final FormData entries:');
      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }
  
      const updatedCompany = await profileApi.updateCompanyProfile(formData);
      setCompanyDetails(updatedCompany);
      setIsEditingCompany(false);
    } catch (error) {
      console.error('Error updating company profile:', error);
    }
  };

  const handleSignOut = () => {
    setConfirmSignOut(true);
  };

  const confirmSignOutAction = () => {
    removeToken();
    navigate("/login");
  };

  const cancelUserEdit = () => {
    setEditedUserData(userDetails);
    setIsEditingUser(false);
  };

  const cancelCompanyEdit = () => {
    setEditedCompanyData(companyDetails);
    setIsEditingCompany(false);
  };

  const handleLogoChange = (file, error) => {
    if (error) {
      setLogoError(error);
      return;
    }
    
    setLogoError(null);
    setEditedCompanyData({
      ...editedCompanyData,
      logo: file
    });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader className="animate-spin h-12 w-12 text-orange-600" />
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-50 min-h-screen pt-20 md:pt-30">
      {/* Header */}
      <Card className="mt-8">
        <CardHeader>
          <CardTitle>
            <span className="text-4xl font-bold">
              <span className="text-black">{t("my")}</span>{" "}
              <span className="text-orange-600">{t("account")}</span>
            </span>
          </CardTitle>
        </CardHeader>
      </Card>

      {/* User Details Section */}
      <Card className="mt-8">
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>Personal Information</CardTitle>
            {!isEditingUser ? (
              <StyledButton
                onClick={() => setIsEditingUser(true)}
                className="bg-transparent hover:bg-gray-100 text-gray-700 px-4 py-2"
              >
                Edit
              </StyledButton>
            ) : (
              <div className="flex gap-2">
                <StyledButton
                  onClick={handleUserUpdate}
                >
                  <Save className="w-4 h-4 mr-2" />
                  Save
                </StyledButton>
                <StyledButton
                  onClick={cancelUserEdit}
                >
                  <X className="w-4 h-4 mr-2" />
                  Cancel
                </StyledButton>
              </div>
            )}
          </div>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <EditableInfoItem 
              icon={User} 
              label="Username"
              value={userDetails?.username}
              field="username"
              isEditing={isEditingUser}
              data={editedUserData}
              setData={setEditedUserData}
            />
            <EditableInfoItem 
              icon={Mail} 
              label="Email"
              value={userDetails?.email}
              field="email"
              isEditing={isEditingUser}
              data={editedUserData}
              setData={setEditedUserData}
            />
            <EditableInfoItem 
              icon={Phone} 
              label="Phone"
              value={userDetails?.phone}
              field="phone"
              isEditing={isEditingUser}
              data={editedUserData}
              setData={setEditedUserData}
            />
            <EditableInfoItem 
              icon={MapPin} 
              label="Address"
              value={userDetails?.address}
              field="address"
              isEditing={isEditingUser}
              data={editedUserData}
              setData={setEditedUserData}
            />
          </div>
        </CardContent>
      </Card>

      {/* Company Details Section */}
      <Card className="mt-8">
        <CardHeader>
          <div className="flex justify-between items-center">
            <CardTitle>Company Information</CardTitle>
            {companyDetails && !isEditingCompany ? (
              <StyledButton
                onClick={() => {
                  setIsEditingCompany(true);
                  setEditedCompanyData(companyDetails);
                }}
                className="bg-transparent hover:bg-gray-100 text-gray-700 px-4 py-2"
              >
                Edit
              </StyledButton>
            ) : isEditingCompany ? (
              <div className="flex gap-2">
                <StyledButton
                  onClick={handleCompanyUpdate}
                >
                  <Save className="w-4 h-4 mr-2" />
                  Save
                </StyledButton>
                <StyledButton
                  onClick={cancelCompanyEdit}
                >
                  <X className="w-4 h-4 mr-2" />
                  Cancel
                </StyledButton>
              </div>
            ) : null}
          </div>
        </CardHeader>
        <CardContent>
          {companyDetails || isEditingCompany ? (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <EditableInfoItem 
                icon={Building2} 
                label="Company Name"
                value={companyDetails?.name}
                field="name"
                isEditing={isEditingCompany}
                data={editedCompanyData}
                setData={setEditedCompanyData}
              />
              <EditableInfoItem 
                icon={Mail} 
                label="Company Email"
                value={companyDetails?.email}
                field="email"
                isEditing={isEditingCompany}
                data={editedCompanyData}
                setData={setEditedCompanyData}
              />
              <EditableInfoItem 
                icon={Phone} 
                label="Company Phone"
                value={companyDetails?.phone}
                field="phone"
                isEditing={isEditingCompany}
                data={editedCompanyData}
                setData={setEditedCompanyData}
              />
              <EditableInfoItem 
                icon={MapPin} 
                label="Company Address"
                value={companyDetails?.address}
                field="address"
                isEditing={isEditingCompany}
                data={editedCompanyData}
                setData={setEditedCompanyData}
              />

              {/* Add the logo upload component */}
              {isEditingCompany && (
                <div className="col-span-2">
                  <div className="text-sm font-medium text-gray-700 mb-2">Company Logo</div>
                  <CompanyLogoUpload
                    currentLogo={companyDetails?.logo}
                    onLogoChange={handleLogoChange}
                    error={logoError}
                  />
                </div>
              )}
              
              {/* Show current logo when not editing */}
              {!isEditingCompany && companyDetails?.logo && (
                <div className="col-span-2">
                  <div className="text-sm font-medium text-gray-700 mb-2">Company Logo</div>
                  <img 
                    src={companyDetails.logo}
                    alt="Company logo"
                    className="h-32 w-auto object-contain"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="text-center py-6">
              <p className="text-gray-500">No company information available</p>
              <StyledButton
                className="mt-4"
                onClick={() => {
                  setIsEditingCompany(true);
                  setEditedCompanyData({
                    name: '',
                    email: '',
                    phone: '',
                    address: '',
                    logo: null
                  });
                }}
              >
                Add Company Details
              </StyledButton>
            </div>
          )}
        </CardContent>
      </Card>

      {/* Sign Out Button */}
      <div className="fixed bottom-7 left-0 right-0 mx-auto flex flex-col space-y-4 max-w-md md:max-w-xl lg:max-w-2xl px-4">
        <StyledButton
          onClick={handleSignOut}
        >
          {t("signOut")}
        </StyledButton>
      </div>

      {/* Confirmation Modal */}
      {/* <ConfirmationModal
        isOpen={confirmSignOut}
        onClose={() => setConfirmSignOut(false)}
        title="Sign Out"
        description="Are you sure you want to sign out?"
        actionLabel="Sign Out"
        onConfirm={confirmSignOutAction}
        variant="warning"
      /> */}
    </div>
  );
};

export default MyAccount;