// profileApi.js
import axios from 'axios';

const isTokenExpired = () => {
  const token = localStorage.getItem("accessToken");

  if (!token) return true;

  try {
    const tokenPayload = JSON.parse(atob(token.split(".")[1]));
    const expiryTime = tokenPayload.exp * 1000;
    const currentTime = Date.now();

    return currentTime >= expiryTime;
  } catch (error) {
    console.error("Error parsing token:", error);
    return true;
  }
};

// Create axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      if (isTokenExpired()) {
        localStorage.removeItem("accessToken");
        window.location.href = '/login';
      } else {
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

// Error handling utility
const handleApiError = (error) => {
  if (error.response) {
    // Server responded with error
    if (error.response.data.detail) {
      return error.response.data.detail;
    }
    if (typeof error.response.data === 'object') {
      // Get first error message from the error object
      const firstError = Object.values(error.response.data)[0];
      if (Array.isArray(firstError)) {
        return firstError[0];
      }
      return firstError;
    }
    return 'An error occurred while processing your request';
  }
  return error.message || 'Network error occurred';
};

export const profileApi = {
  // User profile operations
  getUserProfile: async () => {
    try {
      const response = await apiClient.get('/auth/user/profile/');
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  updateUserProfile: async (userData) => {
    try {
      const response = await apiClient.patch('/auth/user/profile/', userData);
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  changePassword: async (passwordData) => {
    try {
      const response = await apiClient.post('/auth/change-password/', passwordData);
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  // Company profile operations
  getCompanyProfile: async () => {
    try {
      const response = await apiClient.get('/auth/company/profile/');
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },

  updateCompanyProfile: async (formData) => {
    try {
      // Debug log to see what's being sent
      console.log('FormData entries before sending:');
      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }
  
      const response = await apiClient.patch('/auth/company/profile/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw handleApiError(error);
    }
  },
};

export default profileApi;