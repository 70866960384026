import React from 'react';
import { FileText, Loader2 } from 'lucide-react';

const ReportLoader = () => {
  return (
    <div className="fixed inset-0 bg-white bg-opacity-90 backdrop-blur-sm flex flex-col items-center justify-center z-50">
      <div className="bg-white p-8 rounded-xl shadow-lg max-w-sm w-full mx-4 flex flex-col items-center">
        <div className="relative mb-6">
          <FileText className="w-16 h-16 text-orange-500" />
          <div className="absolute inset-0 flex items-center justify-center">
            <Loader2 className="w-8 h-8 text-orange-600 animate-spin" />
          </div>
        </div>
        
        <h3 className="text-lg font-semibold text-gray-900 mb-2">Generating Report</h3>
        <p className="text-gray-600 text-center mb-4">Please wait while we prepare your document...</p>
        
        <p className="text-sm text-gray-500">This may take a few moments</p>
      </div>
    </div>
  );
};

export default ReportLoader;