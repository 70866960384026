// React imports
import React, { useState, useEffect } from 'react';

// Icon imports from lucide-react
import { Mic, Loader, Video } from 'lucide-react';

// Custom hook (assuming it's in your project's hooks directory)
import useTranspileStream from "../../hooks/useTranspileStream";
import { useTranslation } from 'react-i18next';

const AIIcon = () => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    className="w-4 h-4 bg-custom-orange"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12 2.5L19.5 7V17L12 21.5L4.5 17V7L12 2.5Z" />
    <path d="M12 6L16 8.5V13.5L12 16L8 13.5V8.5L12 6Z" />
    <circle cx="12" cy="11" r="1" fill="currentColor" />
  </svg>
);

const ThinkingAIIcon = () => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    className="w-4 h-4 bg-custom-orange"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M12 2.5L19.5 7V17L12 21.5L4.5 17V7L12 2.5Z" />
    <path d="M12 6L16 8.5V13.5L12 16L8 13.5V8.5L12 6Z">
      <animateTransform
        attributeName="transform"
        type="rotate"
        from="0 12 11"
        to="360 12 11"
        dur="2s"
        repeatCount="indefinite"
      />
    </path>
    <circle cx="12" cy="11" r="1" fill="currentColor">
      <animate
        attributeName="opacity"
        values="0.4;1;0.4"
        dur="2s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

const ThinkingIndicator = () => (
  <div className="flex space-x-2 items-center p-2">
    <div
      className="w-2 h-2 bg-blue-600 rounded-full animate-bounce"
      style={{ animationDelay: "0ms" }}
    />
    <div
      className="w-2 h-2 bg-blue-600 rounded-full animate-bounce"
      style={{ animationDelay: "150ms" }}
    />
    <div
      className="w-2 h-2 bg-blue-600 rounded-full animate-bounce"
      style={{ animationDelay: "300ms" }}
    />
  </div>
);

const QuestionDisplay = ({ question }) => (
  <div className="w-full max-w-xl bg-white/60 backdrop-blur-sm border border-gray-200 rounded-lg mb-5">
    <div className="flex items-center gap-3 p-4">
      <svg 
        className="w-5 h-5 text-gray-600 flex-shrink-0" 
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24"
      >
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth="2" 
          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
        />
      </svg>
      <p className="text-gray-800 text-base">
        {question}
      </p>
    </div>
  </div>
);

const VoiceTranscription = ({
  onTranscriptionComplete,
  isEditing,
  editingMessage,
  disabled,
  currentQuestion
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [stream, setStream] = useState(null);
  const {
    startTranscription,
    stopTranscription,
    interimTranscript,
    finalTranscript,
  } = useTranspileStream();
  const { t } = useTranslation();

  const startRecording = async () => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      setStream(audioStream);
      setIsRecording(true);
      startTranscription(audioStream);
    } catch (err) {
      console.error("Error accessing microphone:", err);
    }
  };

  const stopRecording = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
    stopTranscription();
    setIsRecording(false);

    if (finalTranscript.trim()) {
      onTranscriptionComplete(finalTranscript, isEditing, editingMessage);
    }
  };

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
      stopTranscription();
    };
  }, [stream]);

  if (isRecording) {
    return (
      <div className="fixed inset-0 flex flex-col items-center pt-32 bg-white z-50">
        <QuestionDisplay question={currentQuestion} />
        <div className="w-full max-w-lg text-center mb-8">
          <div className="text-lg font-medium text-gray-900 mb-2">
            {isEditing ? "Editing message..." : t("recordingNewMessage")}
          </div>
          <div className="text-lg font-medium text-gray-900 mb-2">
            {interimTranscript || t("listening")}
          </div>
          <div className="text-gray-600">{finalTranscript}</div>
        </div>

        <div className="flex items-center justify-center space-x-4">
          <div className="relative">
            <div className="absolute inset-0 rounded-full animate-ping bg-blue-100 opacity-75" />
            <button
              onClick={stopRecording}
              className="relative z-10 w-16 h-16 bg-blue-600 rounded-full flex items-center justify-center text-white hover:bg-blue-700 transition-colors"
            >
              <Mic size={24} />
            </button>
          </div>
        </div>

        <div className="mt-4 text-sm text-gray-500">
          {t("clickMicToStop")}
        </div>
      </div>
    );
  }

  return (
    <button
      onClick={startRecording}
      disabled={disabled}
      className="p-2 hover:bg-gray-100 rounded-full transition-colors"
      title={isEditing ? "Edit with voice" : "Record audio"}
    >
      <Mic size={20} className="text-gray-600" />
    </button>
  );
};

const ScopeConfirmationModal = ({ onConfirm, onCancel }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full mx-4">
      <div className="flex flex-col items-center text-center space-y-4">
        {/* Warning Icon */}
        <div className="w-12 h-12 bg-yellow-100 rounded-full flex items-center justify-center">
          <svg 
            className="w-6 h-6 text-yellow-600" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth="2" 
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>

        {/* Confirmation Message */}
        <div className="space-y-2">
          <h3 className="text-lg font-semibold text-gray-900">
            Complete Scope?
          </h3>
          <p className="text-gray-600">
            Are you sure you want to complete the scope? This will mark your project as complete and you'll be redirected to the project details page.
          </p>
        </div>

        {/* Action Buttons */}
        <div className="flex gap-3 w-full pt-4">
          <button
            onClick={onConfirm}
            className="flex-1 px-4 py-2 bg-[#f59e0b] text-white rounded-lg font-medium hover:bg-orange-600 transition-colors"
          >
            Yes, Complete
          </button>
          <button
            onClick={onCancel}
            className="flex-1 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg font-medium hover:bg-gray-200 transition-colors"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
);

const VideoUploadStatus = () => {
  return (
    <div className="flex justify-end">
      <div className="flex items-start space-x-4 max-w-[80%] flex-row-reverse space-x-reverse">
        <div className="w-8 h-8 rounded-full flex-shrink-0 bg-[#f59e0b]">
          <div className="w-full h-full rounded-full flex items-center justify-center text-white">
            <Video size={20} />
          </div>
        </div>
        <div className="space-y-2 text-right">
          <div className="prose prose-sm inline-block rounded-2xl px-4 py-2 bg-[#f59e0b] text-white">
            <div className="flex items-center gap-2">
              <Loader className="animate-spin" size={16} />
              <span>Uploading video...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AIIcon, ThinkingAIIcon, ThinkingIndicator, VoiceTranscription, ScopeConfirmationModal, VideoUploadStatus, QuestionDisplay };
