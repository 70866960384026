import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import createApiClient from '../../../api/apiClient';
import useHandleSessionExpired from '../../../utils/useHandleSessionExpired';

const PublicDocumentViewer = () => {
  const { uuid } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const handleSessionExpired = useHandleSessionExpired();
  const apiClient = React.useMemo(
    () => createApiClient(() => handleSessionExpired()),
    [handleSessionExpired]
  );

  useEffect(() => {
    const fetchDocument = async () => {
      try {
        // Note: For public routes, we don't need authentication headers
        const response = await apiClient.get(`documents/public/${uuid}/`, {
          headers: { Authorization: null } // Explicitly remove auth headers for public routes
        });
        
        if (response.data?.url) {
          window.location.href = response.data.url;
        } else {
          throw new Error('No document URL found');
        }
      } catch (err) {
        console.error('Error:', err);
        setError(err.response?.data?.error || err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDocument();
  }, [uuid, apiClient]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        <p className="mt-4 text-gray-600">Loading your document...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
          <h1 className="text-2xl font-bold text-red-600 mb-4">Unable to Load Document</h1>
          <p className="text-gray-600 mb-4">{error}</p>
          <p className="text-sm text-gray-500">If you believe this is an error, please contact support.</p>
        </div>
      </div>
    );
  }

  return null; // Component will redirect before rendering
};

const QRRedirect = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const apiClient = createApiClient(null); // No session expiration handler needed for public route

  useEffect(() => {
    const fetchDocumentId = async () => {
      try {
        // Add trailing slash for Django URL
        const response = await apiClient.get(`documents/redirect/${projectId}/`, {
          headers: { Authorization: null } // Remove auth headers for public route
        });
        
        console.log('Response:', response.data); // Debug log
        
        if (response.data?.url) {
          // Direct to S3 URL
          window.location.href = response.data.url;
        } else if (response.data?.document_id) {
          // Redirect to document viewer
          window.location.href = `/documents/public/${response.data.document_id}`;
        } else {
          console.error('Invalid response format:', response.data);
          navigate('/document-error');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
        navigate('/document-error');
      }
    };

    fetchDocumentId();
  }, [projectId, navigate, apiClient]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      <p className="mt-4 text-gray-600">Loading document...</p>
    </div>
  );
};

const PublicDocumentError = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
        <h1 className="text-2xl font-bold text-red-600 mb-4">Document Not Found</h1>
        <p className="text-gray-600 mb-4">
          We were unable to locate the requested document. This could be because:
        </p>
        <ul className="list-disc list-inside text-gray-600 mb-4">
          <li>The document has expired</li>
          <li>The URL is incorrect</li>
          <li>The document has been removed</li>
        </ul>
        <p className="text-sm text-gray-500">
          If you believe this is an error, please contact support.
        </p>
      </div>
    </div>
  );
};

export { PublicDocumentViewer, QRRedirect, PublicDocumentError };