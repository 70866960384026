import {
  Building2,
  CheckCircle,
  Clock,
  Eye,
  Palette,
  Pencil,
  Plus,
  Star,
  Search,
  ArrowUpDown,
} from "lucide-react";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { isAuthenticated } from "../../utils/auth";
import useHandleSessionExpired from "../../utils/useHandleSessionExpired";
import { Alert, AlertDescription } from "../ui/alert";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import StyledButton from "../ui/styledButton";
import StyledInput from "../ui/styledInput";
import ProgressBar from "../ui/progressBar";

// Create a reusable Image component with URL handling
const ProjectImage = ({ src, alt }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const placeholderUrl = "https://www.naftomar.gr/wp-content/uploads/2023/11/Image_not_available.png"; // Using the placeholder API for fallback

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  return (
    <div className="relative w-16 h-16">
      {/* Loading skeleton */}
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse rounded-lg" />
      )}
      
      {/* Main image */}
      {!hasError ? (
        <img
          src={src}
          alt={alt}
          className={`min-w-16 min-h-16 object-cover rounded-lg transition-opacity duration-300 ${
            isLoading ? 'opacity-0' : 'opacity-100'
          }`}
          loading="lazy"
          onLoad={handleLoad}
          onError={handleError}
        />
      ) : (
        // Fallback image when there's an error
        <img
          src={placeholderUrl}
          alt={alt}
          width={200}
          height={200}
          className="w-16 h-16 object-cover rounded-lg"
          loading="lazy"
        />
      )}
    </div>
  );
};

const DashboardScreen = () => {
  const handleSessionExpired = useHandleSessionExpired();
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState({
    search: "",
    status: "all", // New status filter: 'all', 'inProgress', or 'completed'
  });
  
  const [sortConfig, setSortConfig] = useState({
    key: "date_created",
    direction: "desc",
  });
  
  const [showSortMenu, setShowSortMenu] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!projects?.length) {
      fetchProjects();
    }
  }, []);

  useEffect(() => {
    let filtered = projects.filter((project) => {
      const matchesSearch = project.name
        .toLowerCase()
        .includes(appliedFilters.search?.toLowerCase() || "");
      
      const matchesStatus = appliedFilters.status === "all" 
        ? true 
        : appliedFilters.status === "completed" 
          ? project.completed 
          : !project.completed;

      return matchesSearch && matchesStatus;
    });

    // Apply sorting
    filtered.sort((a, b) => {
      if (sortConfig.key === "name") {
        return sortConfig.direction === "asc"
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      } else {
        const dateA = new Date(a[sortConfig.key]);
        const dateB = new Date(b[sortConfig.key]);
        return sortConfig.direction === "asc" ? dateA - dateB : dateB - dateA;
      }
    });

    setFilteredProjects(filtered);
  }, [appliedFilters, projects, sortConfig]);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => ({
      key,
      direction:
        prevConfig.key === key && prevConfig.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const fetchProjects = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/projects/`,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.status === 401) {
        handleSessionExpired();
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch projects");
      }

      const data = await response.json();
      setProjects(data);
    } catch (err) {
      // setError(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getQualityColor = (quality) => {
    switch (quality) {
      case "A":
        return "text-green-600 bg-green-100";
      case "B":
        return "text-yellow-600 bg-yellow-100";
      case "C":
        return "text-red-600 bg-red-100";
      default:
        return "text-gray-600 bg-gray-100";
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Alert variant="destructive" className="mb-4 mt-[4rem] md:mt-40">
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </div>
    );
  }
  
  return (
    // <div
    //   className={`3xl:container mx-auto px-4 md:px-14 3xl:px-4 pt-28 pb-16 bg-gray-50 min-h-screen ${
    //     isAuthenticated() ? "pt-24 md:pt-[5rem] md:pt-30 lg:pt-28" : "pt-0"
    //   }`}
    // >
    <div className="3xl:container mx-auto px-4 md:px-14 3xl:px-4 pt-28 pb-16 bg-gray-50 min-h-screen pt-[100px] mb-2 md:pt-[120px]">

      {/* Projects List */}
      {projects?.length ? (
        <div className=" md:pt-6 space-y-4">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-800">
              {t("projects")} ({projects.length})
            </h2>
            <div className="flex items-center md:gap-2">
              <StyledInput
                className="!mb-0 w-48 sm:!w-60"
                icon={Search}
                placeholder={t("search")}
                onChange={(e) =>
                  setAppliedFilters({
                    ...appliedFilters,
                    search: e.target.value,
                  })
                }
              />
              <Link to="/create-project">
                <StyledButton className="bg-orange-600 hidden md:block hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-lg shadow-md hover:shadow-lg transition duration-300">
                  <Plus className="w-5 h-5 mr-2 inline" />
                  {t("newProject")}
                </StyledButton>
              </Link>
            </div>
          </div>
          <div className="w-full flex items-center justify-between">
          <div className="flex items-center space-x-4 ml-4">
                {/* All Projects */}
                <button
                  onClick={() => setAppliedFilters(prev => ({ ...prev, status: 'all' }))}
                  className={`flex items-center space-x-2 px-3 py-1 rounded-full transition-colors ${
                    appliedFilters.status === 'all' 
                      ? 'bg-gray-200' 
                      : 'hover:bg-gray-100'
                  }`}
                >
                  <span className="text-gray-800 font-normal text-sm whitespace-nowrap">
                    {t("all")}
                  </span>
                </button>

                {/* In Progress Filter */}
                <button
                  onClick={() => setAppliedFilters(prev => ({ ...prev, status: 'inProgress' }))}
                  className={`flex items-center space-x-2 px-3 py-1 rounded-full transition-colors ${
                    appliedFilters.status === 'inProgress' 
                      ? 'bg-yellow-100' 
                      : 'hover:bg-gray-100'
                  }`}
                >
                  <Clock className="w-4 h-4 text-yellow-500" />
                  <span className="text-gray-800 font-normal text-sm whitespace-nowrap">
                    {t("inProgress")}
                  </span>
                </button>

                {/* Completed Filter */}
                <button
                  onClick={() => setAppliedFilters(prev => ({ ...prev, status: 'completed' }))}
                  className={`flex items-center space-x-2 px-3 py-1 rounded-full transition-colors ${
                    appliedFilters.status === 'completed' 
                      ? 'bg-green-100' 
                      : 'hover:bg-gray-100'
                  }`}
                >
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span className="text-gray-800 font-normal text-sm">
                    {t("completed")}
                  </span>
                </button>
              </div>

            <div className="w-full flex justify-end relative">
              {/* Desktop Sort Dropdown */}
              <select
                className="px-3 py-2 border rounded-lg text-sm hidden md:block"
                value={`${sortConfig.key}-${sortConfig.direction}`}
                onChange={(e) => {
                  const [key, direction] = e.target.value.split("-");
                  setSortConfig({ key, direction });
                }}
              >
                <option value="date_created-desc">{t("newestFirst")}</option>
                <option value="date_created-asc">{t("oldestFirst")}</option>
                <option value="name-asc">{t("nameAZ")}</option>
                <option value="name-desc">{t("nameZA")}</option>
                <option value="last_modified-desc">{t("recentlyModified")}</option>
              </select>

              {/* Mobile Sort Icon */}
              <div className="md:hidden">
                <button
                  onClick={() => setShowSortMenu(!showSortMenu)}
                  className="p-2 hover:bg-gray-100 rounded-lg"
                >
                  <ArrowUpDown className="w-5 h-5 text-gray-600" />
                </button>
                
                {/* Mobile Sort Menu */}
                {showSortMenu && (
                  <div className="absolute w-52 right-0 top-12 bg-white shadow-lg rounded-lg py-2 z-20">
                    <button
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => {
                        setSortConfig({ key: "date_created", direction: "desc" });
                        setShowSortMenu(false);
                      }}
                    >
                      {t("newestFirst")}
                    </button>
                    <button
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => {
                        setSortConfig({ key: "date_created", direction: "asc" });
                        setShowSortMenu(false);
                      }}
                    >
                      {t("oldestFirst")}
                    </button>
                    <button
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => {
                        setSortConfig({ key: "name", direction: "asc" });
                        setShowSortMenu(false);
                      }}
                    >
                      {t("nameAZ")}
                    </button>
                    <button
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => {
                        setSortConfig({ key: "name", direction: "desc" });
                        setShowSortMenu(false);
                      }}
                    >
                      {t("nameZA")}
                    </button>
                    <button
                      className="w-full text-left px-4 py-2 hover:bg-gray-100"
                      onClick={() => {
                        setSortConfig({ key: "last_modified", direction: "desc" });
                        setShowSortMenu(false);
                      }}
                    >
                      {t("recentlyModified")}
                    </button>
                  </div>
                )}
              </div>
            </div>

            {/* Button for smaller screens */}
            <Link to="/create-project">
              <StyledButton className="bg-orange-600 block md:hidden hover:bg-orange-700 text-white font-bold py-2 px-4 rounded-full shadow-md hover:shadow-lg transition duration-300 fixed bottom-4 left-1/2 transform -translate-x-1/2 w-[90%] z-10">
                {t("startNewProject")}
              </StyledButton>
            </Link>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-6 pb-8 md:pb-0">
            {filteredProjects.map((project) => (
              <Link
                key={project.id}
                className="bg-white rounded-lg"
                to={`/project/${project.id}`}
              >
                <div className="border-b-2 border-gray">
                  <div className="hover:bg-slate-50 p-4">
                    <div className="flex justify-between items-center mb-3">
                      <div className="flex items-center space-x-2 xl:space-x-4">
                        <ProjectImage
                          src={project.property_image}
                          alt={`${project.name} thumbnail`}
                        />
                        <div className="flex flex-col space-y-2">
                          <CardTitle className="text-lg font-semibold text-gray-800">
                            {project.name}
                          </CardTitle>
                          <p
                            className={`inline-flex px-2 py-0.5 w-[4rem] rounded-full text-xs font-medium ${getQualityColor(
                              project.quality
                            )}`}
                          >
                            {t("quality")}&nbsp;{project.quality}
                          </p>
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        {project.completed ? (
                          <CheckCircle className="w-4 h-4 text-green-500" />
                        ) : (
                          <Clock className="w-4 h-4 text-yellow-500" />
                        )}
                        <button className="text-gray-500 hover:bg-gray-50">
                          <Pencil className="w-4 h-4" />
                        </button>
                        <button className="text-blue-500 hover:bg-blue-50">
                          <Eye className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                    
                    {/* Progress Bar Section */}
                    <div className="mt-3">
                      <div className="flex justify-between items-center mb-1">
                        <span className="text-sm text-gray-600">Progress</span>
                      </div>
                      <ProgressBar percentage={Math.round(project.pc_completion)} />
                    </div>
                  </div>
                  <div className="text-gray-500 text-xs flex justify-between w-full py-2 px-2">
                    <span>
                      {"created: " +
                        new Date(project.date_created).toLocaleDateString()}
                    </span>
                    <span>
                      {"modified: " +
                        new Date(project.last_modified).toLocaleDateString()}
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : null}
      {filteredProjects.length === 0 && (
        <div className="text-center py-8  md:py-12 bg-white rounded-lg shadow-lg">
          <Building2 className="w-16 h-16 mx-auto text-gray-400 mb-4" />
          <h3 className="text-xl font-semibold text-gray-800 mb-2">
            No Projects Yet
          </h3>
          <p className="text-gray-500 mb-6">
            Get started by creating your first project!
          </p>
          <Link to="/create-project">
            <StyledButton className="bg-orange-600 hover:bg-orange-700 text-white font-bold py-2 px-6 rounded-lg shadow-md hover:shadow-lg transition duration-300">
              Create Your First Project
            </StyledButton>
          </Link>
        </div>
      )}
    </div>
  );
};

export default DashboardScreen;
